<template>
  <div id="BigstripOrderProgressItemsContainer">
    <div id="BigstripOrderProgressItems" :trigger="trigger">
      <bigstrip-order-progress-item
          v-for="(elem, index) in items"
          :key="index" :last="elem.last"
          :active="elem.active"
          :id="index"
          @switchToStep="onSwitchToStep"
      ></bigstrip-order-progress-item>
    </div>
  </div>
</template>

<script>
import BigstripOrderProgressItem from "@/components/orderpage/BigstripOrderProgressItem";

export default {
  name: "BigstripOrderProgressbar",
  components: {
    BigstripOrderProgressItem
  },
  data() {
    return {
      trigger: 0,
      items: [
        {},
        {},
        {},
        {},
        {},
        {last: true}
      ]
    }
  },
  methods: {
    updateProgressbar(progress) {
      for (let n = 0;n < this.items.length;n++) {
        if (n < progress) {
          this.items[n]['active'] = true
        } else if (n === progress) {
          this.items[n]['active'] = true
        } else {
          this.items[n]['active'] = false
        }
      }
      this.trigger++
    },
    onSwitchToStep(step) {
      this.$emit('switchToStep', step)
    }
  }
}
</script>

<style scoped>
  #BigstripOrderProgressItemsContainer {
    max-width: var(--bigstrip-size);
    margin: 0px auto;
  }

  #BigstripOrderProgressItems {
    padding: 10px 20px;
    background-color: var(--bigstrip-light-color);
    height: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 8px;
    grid-template-areas: ". . . . . .";
  }
</style>
