<template>
  <div class="bigstrip-checkout-form-container">
    <div class="bigstrip-checkout-form"></div>
  </div>
</template>

<script>
export default {
  name: "BigstripPaymentForm",
  data() {
    return {
      card: {},
      complete: false,
      stripe: {},
      ready: false,
    }
  },
  methods: {
    init() {
      let context = this;
      // eslint-disable-next-line no-undef
      let elements = this.stripe.elements({ locale: 'DE' });

      let style = {
        base: {
          color: '#303238',
          fontSize: '16px',
          fontFamily: '"Open Sans", sans-serif',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#CFD7DF',
          },
        },
        invalid: {
          color: '#e5424d',
          ':focus': {
            color: '#303238',
          },
        },
      };
      this.card = elements.create('card', { style: style, hidePostalCode: true });
      this.card.mount('.bigstrip-checkout-form');
      this.card.on('change', (e) => {
        if (e.complete) {
          context.$emit('formCompleted', true)
        } else {
          context.$emit('formCompleted', false)
        }
      })
      this.$emit('formCompleted', false)
    },
    async pay(clientSecret) {
      const result = await this.stripe.handleCardPayment(
          clientSecret, this.card, {}
      );
      if (result['error']) {
        return result['error']['payment_intent']
      } else {
        return result['paymentIntent']
      }
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
  }
}
</script>

<style scoped>

.bigstrip-checkout-form-container {
  margin: 15px;
  border: 1px solid var(--bigstrip-color);
  padding: 12px 8px;
}

.bigstrip-checkout-form.bigstrip-checkout-form {
  background-color: #fff;
}

.bigstrip-checkout-form.bigstrip-checkout-form * {
  font-family: Source Code Pro, Consolas, Menlo, monospace;
  font-size: 16px;
  font-weight: 500;
}

.bigstrip-checkout-form.bigstrip-checkout-form .row {
  display: -ms-flexbox;
  display: flex;
  margin: 0 5px 10px;
}

.bigstrip-checkout-form.bigstrip-checkout-form .field {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 0 10px;
}

.bigstrip-checkout-form.bigstrip-checkout-form .field.half-width {
  width: 50%;
}

.bigstrip-checkout-form.bigstrip-checkout-form .field.quarter-width {
  width: calc(25% - 10px);
}

.bigstrip-checkout-form.bigstrip-checkout-form .baseline {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  background-color: #cfd7df;
  transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.bigstrip-checkout-form.bigstrip-checkout-form label {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 8px;
  color: #cfd7df;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform-origin: 0 50%;
  cursor: text;
  pointer-events: none;
  transition-property: color, transform;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.bigstrip-checkout-form.bigstrip-checkout-form .input {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding-bottom: 7px;
  color: #32325d;
  background-color: transparent;
}

.bigstrip-checkout-form.bigstrip-checkout-form .input::-webkit-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.bigstrip-checkout-form.bigstrip-checkout-form .input::-moz-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.bigstrip-checkout-form.bigstrip-checkout-form .input:-ms-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.bigstrip-checkout-form.bigstrip-checkout-form .input.StripeElement {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: opacity;
}

.bigstrip-checkout-form.bigstrip-checkout-form .input.focused,
.bigstrip-checkout-form.bigstrip-checkout-form .input:not(.empty) {
  opacity: 1;
}

.bigstrip-checkout-form.bigstrip-checkout-form .input.focused::-webkit-input-placeholder,
.bigstrip-checkout-form.bigstrip-checkout-form .input:not(.empty)::-webkit-input-placeholder {
  color: #cfd7df;
}

.bigstrip-checkout-form.bigstrip-checkout-form .input.focused::-moz-placeholder,
.bigstrip-checkout-form.bigstrip-checkout-form .input:not(.empty)::-moz-placeholder {
  color: #cfd7df;
}

.bigstrip-checkout-form.bigstrip-checkout-form .input.focused:-ms-input-placeholder,
.bigstrip-checkout-form.bigstrip-checkout-form .input:not(.empty):-ms-input-placeholder {
  color: #cfd7df;
}

.bigstrip-checkout-form.bigstrip-checkout-form .input.focused + label,
.bigstrip-checkout-form.bigstrip-checkout-form .input:not(.empty) + label {
  color: #aab7c4;
  transform: scale(0.85) translateY(-25px);
  cursor: default;
}

.bigstrip-checkout-form.bigstrip-checkout-form .input.focused + label {
  color: var(--bigstrip-color);
}

.bigstrip-checkout-form.bigstrip-checkout-form .input.invalid + label {
  color: var(--bigstrip-color);
}

.bigstrip-checkout-form.bigstrip-checkout-form .input.focused + label + .baseline {
  background-color: var(--bigstrip-color);
}

.bigstrip-checkout-form.bigstrip-checkout-form .input.focused.invalid + label + .baseline {
  background-color: #e25950;
}

.bigstrip-checkout-form.bigstrip-checkout-form input, .bigstrip-checkout-form.bigstrip-checkout-form button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}

.bigstrip-checkout-form.bigstrip-checkout-form input:-webkit-autofill {
  -webkit-text-fill-color: #aeaeae;
  transition: background-color 100000000s;
  -webkit-animation: 1ms void-animation-out;
}

.bigstrip-checkout-form.bigstrip-checkout-form .StripeElement--webkit-autofill {
  background: transparent !important;
}

.bigstrip-checkout-form.bigstrip-checkout-form input, .bigstrip-checkout-form.bigstrip-checkout-form button {
  -webkit-animation: 1ms void-animation-out;
}

.bigstrip-checkout-form.bigstrip-checkout-form button {
  display: block;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: var(--bigstrip-color);
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.bigstrip-checkout-form.bigstrip-checkout-form .error svg {
  margin-top: 0 !important;
}

.bigstrip-checkout-form.bigstrip-checkout-form .error svg .base {
  fill: #e25950;
}

.bigstrip-checkout-form.bigstrip-checkout-form .error svg .glyph {
  fill: #fff;
}

.bigstrip-checkout-form.bigstrip-checkout-form .error .message {
  color: #e25950;
}

.bigstrip-checkout-form.bigstrip-checkout-form .success .icon .border {
  stroke: var(--bigstrip-color);
}

.bigstrip-checkout-form.bigstrip-checkout-form .success .icon .checkmark {
  stroke: var(--bigstrip-color);
}

.bigstrip-checkout-form.bigstrip-checkout-form .success .title {
  color: #32325d;
  font-size: 16px !important;
}

.bigstrip-checkout-form.bigstrip-checkout-form .success .message {
  color: #8898aa;
  font-size: 13px !important;
}

.bigstrip-checkout-form.bigstrip-checkout-form .success .reset path {
  fill: var(--bigstrip-color);
}
</style>