<template>
  <div class="bigstrip-order-form">
    <div class="bigstrip-step-headline" style="text-transform: capitalize;">Rechnungsadresse</div>
    <bigstrip-general-form-data ref="billing_address" @update="onUpdateBillingAddress"/>
    <checkbox ref="different_address" class="bigstrip-order-form-different-deliver-address" @toggle="onToggle">Abweichende Lieferadresse</checkbox>
    <div v-if="different_deliver_address">
      <div class="bigstrip-step-headline" style="text-transform: capitalize;">Lieferadresse</div>
      <bigstrip-general-form-data ref="deliver_address" @update="onUpdateDeliverAddress"/>
    </div>
  </div>
</template>

<script>
import BigstripGeneralFormData from "@/components/orderpage/form/BigstripGeneralFormData";
import Checkbox from "@/components/common/Checkbox";

export default {

  name: "BigstripOrderForm",
  components: {
    BigstripGeneralFormData,
    Checkbox
  },
  data() {
    return {
      different_deliver_address: false
    }
  },
  methods: {
    onUpdateBillingAddress(data) {
      if (data !== null) {
        this.$store.dispatch('setBillingAddress', data)
        if (!this.different_deliver_address) {
          this.$store.dispatch('setFormValid', true)
        } else if (this.$refs.deliver_address.isValid()) {
          this.$store.dispatch('setFormValid', true)
        }
      } else {
        this.$store.dispatch('setFormValid', false)
      }
    },
    onUpdateDeliverAddress(data) {
      if (this.different_deliver_address) {
        if (data !== null) {
          this.$store.dispatch('setDeliverAddress', data)
          if (this.$refs.billing_address.isValid()) {
            this.$store.dispatch('setFormValid', true)
          }
        } else {
          this.$store.dispatch('setFormValid', false)
        }
      } else {
        this.$store.dispatch('setFormValid', false)
      }
    },
    async onToggle() {
      let context = this

      this.different_deliver_address = !this.different_deliver_address
      await this.$store.dispatch('setDifferentAddress', this.different_deliver_address)

      if (this.different_deliver_address) {
        this.waitTillAvailable(context, 'deliver_address')
        .then(() => {
          context.$refs.deliver_address.initForm(context.$store.getters.getDeliverAddress)
        })
        .catch((err) => {
          console.error(err)
        })
        await this.$store.dispatch('setFormValid', false)
      } else {
        if (this.$refs.billing_address.isValid()) {
          await this.$store.dispatch('setFormValid', true)
        }
      }
    },
    async waitTillAvailable(context, name) {
      return new Promise((resolve, reject) => {
        let timer = undefined, timeout = undefined
        timeout = setTimeout(() => {
          clearInterval(timer)
          reject()
        }, 500)
        timer = setInterval(function () {
          if (context.$refs[name] !== undefined) {
            clearTimeout(timeout)
            clearInterval(timer)
            resolve()
          }
        }, 50)
      })
    }
  },
  async mounted() {
    this.$emit('setupButton', {
      text: 'WEITER',
      visible: true
    })

    this.$refs.billing_address.initForm(this.$store.getters.getBillingAddress)

    if (this.$store.getters.hasDeliverAddress) {
      await this.$refs.different_address.setChecked(true)
      await this.onToggle(true)
    }

  }
}
</script>

<style scoped>

.bigstrip-order-form {

}

.bigstrip-order-form-different-deliver-address {
  margin: 30px 15px;
}

</style>
