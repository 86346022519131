<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "ThinHeadline"
    }
</script>

<style scoped>
    div {
        margin: 30px 0px;
        text-align: center;
        font-size: 2.2rem;
        line-height: 2rem;
        font-weight: bold;
    }
</style>