<template>
  <div id="app">
    <div id="start">
      <bigstrip-header />
    </div>
    <bigstrip-carousel/>

    <div class="bigstrip-mainpage-container">
      <basic-button style="margin-top: 0px !important;" v-on:buttonClicked="forwardToOrderPage">B!G STRIP ERSTELLEN</basic-button>
      <thin-headline>B!G on Instagram</thin-headline>
      <bigstrip-insta-container />
      <basic-button color="#B0A682">weitere ansehen</basic-button>
      <thin-headline id="howto">Wie funktioniert B!G STRIPS?</thin-headline>
      <bigstrip-info-region />
      <basic-button v-on:buttonClicked="forwardToOrderPage">B!G STRIP BESTELLEN</basic-button>
      <thin-headline id="locations">B!G STRIPS Standorte</thin-headline>
      <bigstrip-locations />
    </div>

    <div id="faq">
      <bigstrip-f-a-q />
    </div>

    <div class="bigstrip-mainpage-contact-us-container">
      <div class="bigstrip-mainpage-contact-us">
        <thin-headline id="contact">Kontaktiere uns</thin-headline>
        <bigstrip-contact-us-line
            thumbnail="common/icons/phone.svg"
            text="+43 664 123 45 67"
            cta="anrufen"
            link="tel://+436641234567"
        />
        <bigstrip-contact-us-line
            thumbnail="common/icons/email.svg"
            text="office@bigstrips.eu"
            cta="E-Mail"
            link="mailto://office@bigstrips.eu"
        />
      </div>
    </div>

    <div id="delivery">
      <bigstrip-footer />
    </div>
  </div>
</template>

<script>
import BigstripHeader from "@/components/common/BigstripHeader";
import BigstripCarousel from "@/components/mainpage/BigstripCarousel";
import BasicButton from "@/components/common/BasicButton";
import BigstripInstaContainer from "@/components/mainpage/BigstripInstaContainer";
import BigstripInfoRegion from "@/components/mainpage/BigstripInfoRegion";
import ThinHeadline from "@/components/common/ThinHeadline";
import BigstripLocations from "./components/mainpage/BigstripLocations";
import BigstripFAQ from "./components/mainpage/BigstripFAQ";
import BigstripContactUsLine from "@/components/mainpage/BigstripContactUsLine";
import BigstripFooter from "@/components/common/BigstripFooter";

export default {
  name: 'BigstripMainpage',
  components: {
    BigstripFAQ,
    BigstripHeader,
    BigstripCarousel,
    BasicButton,
    BigstripInstaContainer,
    BigstripLocations,
    BigstripInfoRegion,
    ThinHeadline,
    BigstripContactUsLine,
    BigstripFooter
  },
  methods: {
    forwardToOrderPage() {
      this.$router.push('order/import')
    },
    mounted() {
      document.addEventListener('contextmenu', function (e) {
        if (!process.env.VUE_APP_DEVELOPMENT) {
          e.preventDefault()
        }
      })
      // remove footer in leaflet js map
      setInterval(function () {
        document.getElementsByClassName('leaflet-control-attribution')[0].innerHTML = ''
      }, 500)
    }
  }
}
</script>

<style scoped>
  .bigstrip-mainpage-container {
    margin: 0px auto;
    max-width: var(--bigstrip-size);
    background-color: var(--bigstrip-light-color);
  }

  .bigstrip-mainpage-contact-us-container {
    width: 100%;
    background-color: var(--bigstrip-light-color);
  }

  .bigstrip-mainpage-contact-us {
    margin: 0px auto;
    padding: 15px;
    max-width: var(--bigstrip-size);
  }

  @font-face {
    font-family: "Neusa Next Std";
    src: local("Neusa Next Std"),
    url("./fonts/Neusa_Next_Std_regular.otf") format("truetype");
  }

  @font-face {
    font-family: "Neusa Next Std Light";
    src: local("Neusa Next Std Light"),
    url("./fonts/Neusa_Next_Std_light.otf") format("truetype");
  }

  @font-face {
    font-family: "Neusa Next Std Bold";
    src: local("Neusa Next Std Boold"),
    url("./fonts/Neusa_Next_Std_bold.otf") format("truetype");
  }
</style>
