<template>
  <div class="bigstrip-no-connection">
    <bigstrip-header />
    <div class="bigstrip-no-connection-container">
      <div class="bigstrip-no-connection-paragraph">
        <svg id="sad" data-name="sad" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 146.97"><path d="M158.93,365.9A10,10,0,1,0,173.07,380a117.28,117.28,0,0,1,165.86,0,10,10,0,1,0,14.14-14.14,137.27,137.27,0,0,0-194.14,0Z" transform="translate(-156 -236)"/><path d="M204.34,276a20,20,0,1,0-20-20A20,20,0,0,0,204.34,276Z" transform="translate(-156 -236)"/><path d="M287.66,256a20,20,0,1,0,20-20A20,20,0,0,0,287.66,256Z" transform="translate(-156 -236)"/></svg>
        <p>Leider ist unser Bestellsystem vorübergehen nicht erreichbar. Bitte versuchen Sie es später erneut.</p>
      </div>

      <basic-button
          class="bigstrip-failure-back-btn"
          ref="nextbutton"
          @buttonClicked="onNextButtonClicked()"
          color="#B0A682">
        Zur Startseite
      </basic-button>
    </div>
  </div>
</template>

<script>

import BasicButton from "@/components/common/BasicButton";
import BigstripHeader from "@/components/common/BigstripHeader";

export default {
  name: "BigstripNoConncetion",
  components: {
    BasicButton,
    BigstripHeader
  },
  methods: {
    onNextButtonClicked() {
      this.$router.push({
        path: '/'
      })
    }
  },
  mounted() {}
}
</script>

<style scoped>
  .bigstrip-no-connection-paragraph {
    margin: 0px auto 0px auto;
    width: 100%;
    max-width: var(--bigstrip-size);
    position: absolute;
    top: 200px;
  }

  .bigstrip-no-connection-container {
    height: calc(100% - 60px);
    max-width: var(--bigstrip-size);
    margin: 0 auto;
    background-color: var(--bigstrip-light-color);
  }

  .bigstrip-failure-back-btn {
    width: 100%;
    position: fixed;
    bottom: 15px;
  }

  svg {
    max-width: 40%;
    width: 160px;
    filter: opacity(0.8);
    margin-bottom: 50px;
  }

  .bigstrip-no-connection {
    height: 100%;
    background-color: var(--bigstrip-bg-color);
  }

</style>
