<template>
    <div class="BigstripFAQEntry">
        <div class="BigstripFAQEntryButton">V</div>
        <div class="BigstripFAQEntryQuestion" v-on:click="clicked">{{question}}</div>
        <div v-if="answerVisible" class="BigstripFAQEntryAnswer">{{answer}}</div>
        <hr v-if="!noLine"/>
    </div>
</template>

<script>
    export default {
        name: "BigstripsFAQEntry",
        props: {
            'question': {tpye: String},
            'answer': {tpye: String},
            'noLine': {tpye: Boolean, default: false},
        },
        data() {
            return {
                answerVisible: false
            }
        },
        methods: {
            clicked() {
                this.answerVisible = !this.answerVisible
            }
        }
    }
</script>

<style scoped>
    .BigstripFAQEntry {
        margin: 15px;
        text-align: left;
    }

    .BigstripFAQEntryQuestion {
        font: normal normal bold 16px/20px Neusa Next Std;
        margin-top: 20px;
        width: 100%;
    }

    .BigstripFAQEntryButton {
        position: absolute;
        right: 20px;
    }

    .BigstripFAQEntryAnswer {
        font: normal normal normal 16px/20px Neusa Next Std;
        margin-top: 20px;
    }

    .BigstripFAQEntry hr {
        margin-top: 18px;
        border: none;
        border-top: 1px solid var(--bigstrip-light-color);
    }

</style>