import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import createPersistedState from "vuex-persistedstate";
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import BigstripMainpage from './BigstripMainpage.vue'
import BigstripOrderpage from './BigstripOrderpage.vue'
import BigstripImageImporter from './components/orderpage/BigstripImageImporter.vue'
import BigstripConfigurator from './components/orderpage/BigstripConfigurator.vue'
import BigstripOrderForm from './components/orderpage/BigstripOrderForm.vue'
import BigstripSizeChooser from './components/orderpage/BigstripSizeChooser.vue'
import BigstripCheckout from './components/orderpage/BigstripCheckout.vue'
import BigstripProviderChoice from "./components/orderpage/BigstripProviderChoice";
import BigstripSuccess from "./BigstripSuccess";
import BigstripFailure from "./BigstripFailure";

import VueModalTor from "vue-modaltor/dist/vue-modaltor.common";
import "vue-modaltor/dist/vue-modaltor.css";
import BigstripNoConnection from "./BigstripNoConnection";
import BigstripAGBPage from "./components/common/BigstripAGBPage";
import BigstripImpressumPage from "./components/common/BigstripImpressumPage";
import BigstripDatenschutzPage from "./components/common/BigstripDatenschutzPage";

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueModalTor)
Vue.use(VueAxios, axios)

Vue.config.productionTip = false

Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)

const store = new Vuex.Store({
    state: {
        image_rows: [],
        config: {},
        slot_1: undefined,
        slot_2: undefined,
        slot_3: undefined,
        billing_address: {},
        deliver_address: {},
        form_valid: false,
        different_address: false,
        loading: false,
        full: false,
        provider_set: false,
        amount: undefined,
        size: undefined
    },
    plugins: [createPersistedState()],
    getters: {
        getThumbnails: state => {
            return state.images
        },
        customerData: state => {
            return state.customer
        },
        getImageRows: state => {
            return state.image_rows
        },
        getProducts: state => {
            return state.products
        },
        getSize: state => {
            return state.size
        },
        getAmount: state => {
            return state.amount
        },
        getSlot1: state => {
            return state['slot_1']
        },
        getSlot2: state => {
            return state['slot_2']
        },
        getSlot3: state => {
            return state['slot_3']
        },
        getBillingAddress: state => {
            return state.billing_address
        },
        getDeliverAddress: state => {
            return state.deliver_address
        },
        getProvider: state => {
            return state.provider
        },
        hasDeliverAddress: state => {
            return state.different_address
        },
        areSlotsFull: state => {
            return state.full
        },
        isSizeAndAmountProvided: state => {
            return state.amount !== undefined && state.size !== undefined
        },
        isFormValid: state => {
            return state.form_valid
        },
        isProviderSet: state => {
            return state.provider_set
        },
        getHighestThumbnailId: state => {
            let id = 0
            state.image_rows.forEach(image_row => {
                image_row['images'].forEach(image => {
                    if (image.thumbnail > id) {
                        id = image.thumbnail
                    }
                })
            })
            return id+1
        },
        getSlot: (state => (slot) => {
            if (slot === 1) return state['slot_1']
            if (slot === 2) return state['slot_2']
            if (slot === 3) return state['slot_3']
        }),
        getImageData: (state => (code, index) => {
            let src = ''
            state.image_rows.forEach(image_row => {
                if (image_row['code'] === code) {
                    image_row['images'].forEach(image => {
                        if (image['index'] === index) {
                            src = image['src']
                        }
                    })
                }
            })
            return src
        }),
        getThumbnailDataById: (state => (id) => {
            let thumbnail = undefined
            state.image_rows.forEach(image_row => {
                image_row['images'].forEach(image => {
                    if (image['thumbnail'] === id) {
                        thumbnail = {
                            row: image_row,
                            thumbnail: image
                        }
                    }
                })
            })
            return thumbnail
        }),
        getConfigSerialized: (state => (dynamicDummy) => {
            // just do not provide any params
            if (dynamicDummy) console.log(dynamicDummy)
            return JSON.stringify(state.config)
        }),
        doesNotExist: (state => (code) => {
            let exists = false
            state.image_rows.forEach(image_row => {
                if (image_row['code'] === code) {
                    exists = true
                }
            })
            return !exists
        }),
        isLoading: (state => {
            return state.loading
        }),
        hasFinishedInitializing: (state => (dynamicDummy) => {
            // just do not provide any params
            if (dynamicDummy) console.log(dynamicDummy)
            return state.initAfterReload
        }),
        geThumbnailCodes: (state => {
            let codesSet = new Set()
            let codesArray = []

            state.images.forEach(image => {
                codesSet.add(image['code'])
            })

            for (let code of codesSet) {
                codesArray.push(code)
            }

            return codesArray
        }),
        getAvailableThumbnails: (state => () => {
            let availableImages = []

            state.image_rows.forEach(image_row => {
                image_row['images'].forEach(image => {
                    let available = true

                    Object.keys(state)
                        .filter(key => { return key.indexOf('slot_') !== -1 })
                        .forEach(key => {
                            let slot = state[key]
                            if (!slot) return
                            if (image_row['code'] == slot['code']
                                && image['index'] == slot['index']) {
                                available = false
                            }
                        })


                    if (available) {
                        availableImages.push(image)
                    }
                })
            })

            return availableImages
        })
    },
    mutations: {
        clearAll(state) {
            state['image_rows'] = []
            state['config'] = {}
            state['slot_1'] = undefined
            state['slot_2'] = undefined
            state['slot_3'] = undefined
            state['billing_address'] = {}
            state['deliver_address'] = {}
            state['form_valid'] = false
            state['different_address'] = false
            state['loading'] = false
            state['full'] = false
            state['provider_set'] = false
            state['amount'] = undefined
            state['size'] = undefined
        },
        setSize(state, size) {
            state['size'] = size
        },
        setAmount(state, amount) {
            state['amount'] = amount
        },
        removeSlot1(state) {
            state.full = false
            state['slot_1'] = undefined
        },
        removeSlot2(state) {
            state.full = false
            state['slot_2'] = undefined
        },
        removeSlot3(state) {
            state.full = false
            state['slot_3'] = undefined
        },
        updateCustomerData(state, formData) {
            state.customer = formData
        },
        addImageRow(state, imageRow) {
            state.image_rows.push(imageRow)
        },
        removeImageRow(state, code) {
            state.image_rows = state.image_rows.filter(image_row => image_row['code'] !== code)
            if (state['slot_1'] && state['slot_1']['code'] === code) delete state['slot_1']
            if (state['slot_2'] && state['slot_2']['code'] === code) delete state['slot_2']
            if (state['slot_3'] && state['slot_3']['code'] === code) delete state['slot_3']
            state.full = state['slot_1']!==undefined&&state['slot_2']!==undefined&&state['slot_3']!==undefined
            state['billing_address'] = {}
            state['deliver_address'] = {}
            state['form_valid'] = false
            state['different_address'] = false
            state['loading'] = false
            state['full'] = false
            state['provider_set'] = false
            state['amount'] = undefined
            state['size'] = undefined
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setDifferentAddress(state, different_address) {
            state.different_address = different_address
        },
        loadConfig(state, config) {
            console.log(state)
            console.log(config)
            state.config = JSON.parse(config)
        },
        finishInit(state, initStatus) {
            state.initAfterReload = true
            state.initStatus = initStatus
        },
        storeProductSpecification(state, products) {
            state.products = products
        },
        setSlot1(state, slotData) {
            state['slot_1'] = slotData
            state.full = state['slot_1'] !== undefined && state['slot_2'] !== undefined && state['slot_3'] !== undefined
        },
        setSlot2(state, slotData) {
            state['slot_2'] = slotData
            state.full = state['slot_1']!== undefined && state['slot_2'] !== undefined && state['slot_3'] !== undefined

        },
        setSlot3(state, slotData) {
            state['slot_3'] = slotData
            state.full = state['slot_1']!== undefined && state['slot_2'] !== undefined && state['slot_3'] !== undefined

        },
        resetFullIndicator(state) {
            state.full = state['slot_1'] !== undefined && state['slot_2'] !== undefined && state['slot_3'] !== undefined
        },
        setBillingAddress(state, data) {
            state.billing_address = data
        },
        setDeliverAddress(state, data) {
            state.deliver_address = data
        },
        setFormValid(state, valid) {
            state.form_valid = valid
        },
        setProvider(state,  provider) {
            state.provider = provider
            state.provider_set = true
        },
        printAll(state) {
            console.log(state)
        }
    },
    actions: {
        clearAll({ commit }) {
            commit('clearAll')
        },
        setSize({ commit }, size) {
            commit('setSize', size)
        },
        setAmount({ commit }, amount) {
            commit('setAmount', amount)
        },
        setSlot1({ commit }, data) {
            commit('setSlot1', data)
        },
        setSlot2({ commit }, data) {
            commit('setSlot2', data)
        },
        setSlot3({ commit }, data) {
            commit('setSlot3', data)
        },
        removeSlot1({ commit }) {
            commit('removeSlot1')
        },
        removeSlot2({ commit }) {
            commit('removeSlot2')
        },
        removeSlot3({ commit }) {
            commit('removeSlot3')
        },
        resetFullIndicator({ commit }) {
            commit('resetFullIndicator')
        },
        updateCustomerData({ commit }, formData) {
            commit('updateCustomerData', formData)
        },
        addImageRow({ commit }, imageRow) {
            commit('addImageRow', imageRow)
        },
        removeImageRow({ commit }, code) {
            commit('removeImageRow', code)
        },
        setLoading({ commit }, loading) {
            commit('setLoading', loading)
        },
        setDifferentAddress({ commit }, different_address) {
            commit('setDifferentAddress', different_address)
        },
        setBillingAddress({ commit }, data) {
            commit('setBillingAddress', data)
        },
        setDeliverAddress({ commit }, data) {
            commit('setDeliverAddress', data)
        },
        setFormValid({ commit }, valid) {
            commit('setFormValid', valid)
        },
        loadConfig({ commit }, config) {
            commit('loadConfig', config)
        },
        finishInit({ commit }, initStatus) {
            commit('finishInit', initStatus)
        },
        storeProductSpecification({ commit }, products) {
            commit('storeProductSpecification', products)
        },
        setProvider({ commit }, provider) {
            commit('setProvider', provider)
        },
        printAll({ commit }) {
            commit('printAll')
        }
    }
})

const router = new VueRouter({
    routes: [
        {
            path: '/',
            name: 'mainpage',
            component: BigstripMainpage
        },
        {
            path: '/order',
            name: 'orderpage',
            component: BigstripOrderpage,
            children: [
                {
                    path: 'import',
                    name: 'import',
                    component: BigstripImageImporter
                },
                {
                    path: 'config',
                    name: 'config',
                    component: BigstripConfigurator
                },
                {
                    path: 'size',
                    name: 'size',
                    component: BigstripSizeChooser
                },
                {
                    path: 'form',
                    name: 'form',
                    component: BigstripOrderForm
                },
                {
                    path: 'provider',
                    name: 'provider',
                    component: BigstripProviderChoice
                },
                {
                    path: 'checkout',
                    name: 'checkout',
                    component: BigstripCheckout
                },
            ],
        },
        {
            path: '/success',
            name: 'success',
            component: BigstripSuccess
        },
        {
            path: '/failure',
            name: 'failure',
            component: BigstripFailure
        },
        {
            path: '/no-connection',
            name: 'no-connection',
            component: BigstripNoConnection
        },
        {
            path: '/agb',
            name: 'agb',
            component: BigstripAGBPage
        },
        {
            path: '/impressum',
            name: 'impressum',
            component: BigstripImpressumPage
        },
        {
            path: '/datenschutz',
            name: 'datenschutz',
            component: BigstripDatenschutzPage
        },
        {
            path: '/*',
            name: 'mainpage',
            component: BigstripMainpage
        }
    ]
})

let vue = new Vue({
    router: router,
    el: '#app',
    store: store
})

console.log('-----------------------------------------------')
console.log('           VUE DEV HELPER FUNCTION')
console.log('')
console.log('printAll() // shows current store payload')
console.log(function printAll() {
    vue.$store.dispatch('printAll')
})
console.log('clearAll() // set store to the default values')
console.log(function clearAll() {
    vue.$store.dispatch('clearAll')
})
console.log('-----------------------------------------------')

//cd /c/workspace/blitzkabine/chromium && start chrome.exe --disable-web-security --disable-gpu --user-data-dir=./tmp/
