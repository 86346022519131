<template>
  <header>
    <bigstrip-menu />
    <bigstrip-title/>
  </header>
</template>

<script>

import BigstripTitle from "@/components/common/BigstripTitle";
import BigstripMenu from "@/components/mainpage/BigstripMenu";

export default {
  name: "BigstripHeader",
  components: {
    BigstripTitle,
    BigstripMenu
  }
}
</script>

<style scoped>
  header {
    height: 55px;
    background-color: var(--bigstrip-color);
    width: 100%;
  }

</style>
