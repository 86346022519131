<template>
  <div class="bigstrip-image-previewer" v-on:click="resetSize">
    <div class="bigstrip-image-previewer-overlay-container">
      <img class="bigstrip-image-previewer-overlay" ref="overlay" src="orderpage/sizechooser/overlay_alpha_all.svg"/>
    </div>
    <svg version="1.1" class="previewer" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px"
         viewBox="0 0 1060.16 1284.09" style="enable-background:new 0 0 1060.16 1284.09;" xml:space="preserve">
      <g id="big" v-on:click="handleClick('big')">
        <image id="big_1" x="195.76" y="96.55" class="st0" width="170.08" height="251.34"/>
        <image id="big_2" x="195.76" y="347.89" class="st0" width="170.08" height="251.34"/>
        <image id="big_3" x="195.76" y="599.23" class="st0" width="170.08" height="251.34"/>
      </g>
      <g id="normal" v-on:click="handleClick('normal')">
        <image id="normal_1" x="782.4" y="90.29" class="st0" width="113.31" height="167.45"/>
        <image id="normal_2" x="782.4" y="257.74" class="st0" width="113.31" height="167.45"/>
        <image id="normal_3" x="782.4" y="425.18" class="st0" width="113.31" height="167.45"/>
      </g>
      <g id="small" v-on:click="handleClick('small')">
        <image id="small_1" x="559.15" y="851.43" class="st0" width="60.92" height="90.02"/>
        <image id="small_2" x="559.15" y="941.45" class="st0" width="60.92" height="90.02"/>
        <image id="small_3" x="559.15" y="1031.47" class="st0" width="60.92" height="90.02"/>
      </g>
      <text ref="text_big_dim" transform="matrix(1 0 0 1 117.7776 953.2994)" class="st1 st2">{BIG_DIM}</text>
      <text ref="text_big_price" transform="matrix(1 0 0 1 118.3178 1000.5127)" class="st3 st2">{BIG_PRICE}</text>
      <text ref="text_normal_dim" transform="matrix(1 0 0 1 735.4641 673.5729)" class="st1 st2">{NORMAL_DIM}</text>
      <text ref="text_normal_price" transform="matrix(1 0 0 1 736.0043 720.7862)" class="st3 st2">{NORMAL_PRICE}</text>
      <text ref="text_small_dim" transform="matrix(1 0 0 1 674.2343 940.9657)" class="st1 st2">{SMALL_DIM}</text>
      <text ref="text_small_price" transform="matrix(1 0 0 1 674.7745 988.188)" class="st3 st2">{SMALL_PRICE}</text>
      <text transform="matrix(1 0 0 1 707.8243 1239.7195)" class="st1 st2">Gesamt:</text>
      <text v-if="totalVisible" ref="text_total" transform="matrix(1 0 0 1 890.7102 1239.7206)" class="st3 st2">{TOTAL}</text>
    </svg>
  </div>
</template>

<script>
const OVERLAY_MAPPING = {
  big: 'orderpage/sizechooser/overlay_alpha_big.svg',
  normal: 'orderpage/sizechooser/overlay_alpha_normal.svg',
  small: 'orderpage/sizechooser/overlay_alpha_small.svg'
}

const SIZES = ['big', 'normal', 'small']

export default {

  name: "BigstripSizePreviewer",
  computed: {
    totalVisible() {
      return this.$store.getters.getAmount && this.$store.getters.getSize
    }
  },
  methods: {
    handleClick(size) {
      setTimeout(() => {
        this.$emit('selectSize', size)
      })
    },
    async resetSize() {
      await this.$store.dispatch('setSize',   null)

      if (this.$refs['text_total']) {
        this.$refs['text_total'].innerHTML = ''
      }

      this.$refs.overlay.src = 'orderpage/sizechooser/overlay_alpha_all.svg'

      SIZES.forEach(s => {
        this.$refs['text_'+s+'_dim'].classList.remove("active-size")
        this.$refs['text_'+s+'_price'].classList.remove("active-size")
      })
    },
    setSize(size) {
      this.$refs.overlay.src = OVERLAY_MAPPING[size]

      SIZES.forEach(s => {
        this.$refs['text_'+s+'_dim'].classList.remove("active-size")
        this.$refs['text_'+s+'_price'].classList.remove("active-size")
        if (s === size) {
          this.$refs['text_'+s+'_dim'].classList.add("active-size")
          this.$refs['text_'+s+'_price'].classList.add("active-size")
        }
      })
    },
    setTotal(total) {
      if (total) {
        this.$refs['text_total'].innerHTML = '€ ' + total + ',-'
      }
    }
  },
  async mounted() {
    let productSpecs = this.$store.getters.getProducts

    let svg = document.getElementsByClassName('previewer')[0]

    let slot1 = this.$store.getters.getSlot1
    let slot2 = this.$store.getters.getSlot2
    let slot3 = this.$store.getters.getSlot3

    let image1 = this.$store.getters.getImageData(slot1['code'], slot1['index'])
    let image2 = this.$store.getters.getImageData(slot2['code'], slot2['index'])
    let image3 = this.$store.getters.getImageData(slot3['code'], slot3['index'])

    svg.getElementById('big_1').setAttribute('href', image1)
    svg.getElementById('big_2').setAttribute('href', image2)
    svg.getElementById('big_3').setAttribute('href', image3)

    svg.getElementById('normal_1').setAttribute('href', image1)
    svg.getElementById('normal_2').setAttribute('href', image2)
    svg.getElementById('normal_3').setAttribute('href', image3)

    svg.getElementById('small_1').setAttribute('href', image1)
    svg.getElementById('small_2').setAttribute('href', image2)
    svg.getElementById('small_3').setAttribute('href', image3)

    SIZES.forEach(size => {
      let spec = productSpecs[size]

      this.$refs['text_'+size+'_dim'].innerHTML = spec['dim']
      this.$refs['text_'+size+'_price'].innerHTML = '€ '+spec['price']

    })

  }
}
</script>

<style scoped>

.st0{fill:#FFFFFF;}
.st1{font-family:'Neusa Next Std Light';}
.st3{font-family:'Neusa Next Std Bold';}
.st2{font-size:48px;}
.active-size{font-size: 52px;}

  .bigstrip-image-previewer {
    background-image: url("../../../assets/orderpage/sizechooser/bg.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .bigstrip-image-previewer-overlay-container {
    position: relative;
    width: 100%;
    pointer-events: none;
  }

  .bigstrip-image-previewer-overlay {
    position: absolute;
    left: 0px
  }

</style>