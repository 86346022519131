<template>
  <div class="bigstrip-location-details">
    <div v-if="image !== null" class="bigstrip-location-details-preview"><img style="width: 100%" :src="image"/></div>
    <div class="bigstrip-location-details-title">{{ title }}</div>
    <div v-if="displayAddress" class="bigstrip-location-details-address bigstrip-location-details-row">
      <img src="../../assets/mainpage/address.svg">
      <div>{{ address }}</div>
      <img src="../../assets/mainpage/dropdown.svg">
    </div>
    <div v-if="displayOpennings" class="bigstrip-location-details-opennings bigstrip-location-details-row">
      <img src="../../assets/mainpage/opennings.svg" style="padding-right: 1rem">
      <ul>
        <li v-for="(entry, index) in opennings" v-bind:key="index">{{ entry }}</li>
      </ul>
    </div>
    <div v-if="displayWebsite" class="bigstrip-location-details-website bigstrip-location-details-row">
      <img src="../../assets/mainpage/website.svg">
      <div><a :href="website">{{ getHostname(website) }}</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BigstripLocationDetails",
  data() {
    return {
      image: '',
      title: '',
      address: '',
      opennings: '',
      website: null,
      displayAddress: false,
      displayOpennings: false,
      displayWebsite: false,
    }
  },
  methods: {
    update(cabine) {
      this.title = cabine.location.name.split(',')[0]
      this.image = cabine.thumbnail

      this.website = cabine.location.websiteUrl
      this.address = cabine.location.place.address
      this.opennings = cabine.location.place.openingHours

      this.displayAddress = false
      this.displayOpennings = false
      this.displayWebsite = false

      if (this.website !== '' && this.website !== null) {
        this.displayWebsite = true
      }

      if (this.address !== '') {
        this.displayAddress = true
      }

      if (this.opennings.length > 0) {
        this.opennings.forEach((entry) => {
          console.log(entry)
          if (entry !== '-' && entry !== '') {
            this.displayOpennings = true
          }
        })
      }
    },
    getHostname(website) {
      return (new URL(website)).hostname
    }
  }
}
</script>

<style scoped>

.bigstrip-location-details {
  padding: 10px;
  text-align: left;
  overflow: hidden;
}

.bigstrip-location-details-row {
  display: flex;
  flex-direction: row;
  font: normal normal normal 16px/20px "Neusa Next Std";
  margin-bottom: 8px;
}

.bigstrip-location-details-title {
  font: normal normal bold 16px/20px Neusa Next Std;
  margin-bottom: 12px;
  text-transform: uppercase;
}

.bigstrip-location-details-row img:first-child {
  align-self: flex-start !important;
  margin-right: 10px;
}

.bigstrip-location-details-row img {
  align-self: center;
}

.bigstrip-location-details-row div {
  align-self: center;
  width: 100%;
}

</style>