<template>
  <div>
    <bigstrip-header />
    <bigstrip-order-progressbar ref="progressbar" v-on:switchToStep="onSwitchToStep"/>
    <div class="bigstrip-orderpage-container">
      <div class="bigstrip-orderpage-content">
        <router-view
            @validate="onValidate"
            @import="loadThumbnail"
            @setupButton="onSetupButton"
            @switchToNextStep="onSwitchToNextStep"
            @switchToStep="onSwitchToExplicitStep"
        />
        <div class="bigstrip-order-page-bottom-button">
          <basic-button
              ref="nextbutton"
              @buttonClicked="onNextButtonClicked()"
              :color="buttonColor">
            Weiter
          </basic-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BigstripHeader from "@/components/common/BigstripHeader";
import BigstripOrderProgressbar from "@/components/orderpage/BigstripOrderProgressbar";
import BasicButton from "@/components/common/BasicButton";

const STEP_MAPPING = {
  'import': 'config',
  'config': 'size',
  'size': 'form',
  'form': 'provider',
  'provider': 'checkout',
  'checkout': 'import'
}

const INDEX_MAPPING = {
  'import': 0,
  'config': 1,
  'size': 2,
  'form': 3,
  'provider': 4,
  'checkout': 5
}

const REVERSE_MAPPING = [
  'import',
  'config',
  'size',
  'form',
  'provider',
  'checkout'
]

let asyncForEach = async function(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export default {
  name: "BigstripOrder",
  components: {
    BigstripHeader,
    BigstripOrderProgressbar,
    BasicButton
  },
  data: function() {
    return {
      'thumbnailId': 0,
      buttonColor: 'var(--bigstrip-color)'
    }
  },
  methods: {
    onNextButtonClicked() {

      let currentStep = this.$router.currentRoute.name
      let newStep = STEP_MAPPING[currentStep]

      if (this.checkJumpCondition(newStep)) {
        this.$router.push({
          name: newStep
        })
        this.$refs.progressbar.updateProgressbar(INDEX_MAPPING[newStep])
      }
    },
    checkJumpCondition(step) {
      let allowed = false

      if (step === 'import') {
        allowed = true
      }

      if (step === 'config') {
        allowed = this.$store.getters.getImageRows.length > 0
      }

      if (step === 'size') {
        allowed = this.checkJumpCondition('config')
            && this.$store.state['slot_1'] !== undefined
            && this.$store.state['slot_2'] !== undefined
            && this.$store.state['slot_3'] !== undefined
      }

      if (step === 'form') {
        allowed = this.checkJumpCondition('config')
            && this.checkJumpCondition('size')
            && this.$store.getters.isSizeAndAmountProvided
      }

      if (step === 'provider') {
        allowed = this.checkJumpCondition('config')
            && this.checkJumpCondition('size')
            && this.checkJumpCondition('form')
            && this.$store.getters.isFormValid
      }

      if (step === 'checkout') {
        allowed = this.checkJumpCondition('config')
            && this.checkJumpCondition('size')
            && this.checkJumpCondition('form')
            && this.checkJumpCondition('provider')
            && this.$store.getters.isProviderSet
      }

      return allowed
    },
    onValidate(valid) {
      this.$refs.nextbutton.setEnabled(valid)
    },
    onSetupButton(data) {
      this.$refs.nextbutton.setVisible(data.visible)
      this.$refs.nextbutton.setText(data.text)
    },
    async loadThumbnail(code) {

      if (code === '') return
      if (!this.$store.getters.doesNotExist(code)) return

      let imageRow = {
        "code": code,
        images: []
      }

      await this.$store.dispatch('setLoading', true)

      if (process.env.VUE_APP_DEVELOPMENT === "true") {
        let indicies = [1,2,3]
        await asyncForEach(indicies, async index => {
          let bgColor = Math.random().toString(16).substr(2,6)
          let fgColor = '000000'//Math.random().toString(16).substr(2,6)

          let dummyURL = 'https://dummyimage.com/400x600/'+bgColor+'/'+fgColor+'.jpg&text=B!GSTRIPS'
          await new Promise((resolve) => {
            this.$http.get(dummyURL, {
              responseType: 'arraybuffer',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'image/jpeg'
              }
            })
              .then(jpg => {
                let base64 = Buffer.from(jpg['data']).toString('base64')
                imageRow['images'].push({
                  "thumbnail": this.thumbnailId,
                  "index": index,
                  "src": "data:image/jpg;base64,"+base64
                })
                this.thumbnailId++
                resolve()
              })
              .catch(err => console.error(err))
          })
        })

        console.log('Skipped call to api, used dummies instead')

        await this.$store.dispatch('addImageRow', imageRow)
      } else {
        const { data } = await this.$http.get(
            process.env.VUE_APP_API_ENDPOINT+'/images/'+code
        )

        if (data['images'].length === 3) {
            data['images'].forEach(image => {
              imageRow['images'].push({
                "thumbnail": this.thumbnailId,
                "index": image['index'],
                "src": "data:image/png;base64,"+image['image']
              })
              this.thumbnailId++
            })
            await this.$store.dispatch('addImageRow', imageRow)
        } else {
          console.error('invalid amount of images...')
        }
      }

      await this.$store.dispatch('setLoading', false)

      let current = this.$router.currentRoute.name
      if (current === 'import') this.$refs.nextbutton.setEnabled(true)
    },
    onSwitchToNextStep() {
      let nextStep = INDEX_MAPPING[STEP_MAPPING[this.$router.currentRoute.name]]
      this.onSwitchToStep(nextStep)
    },
    onSwitchToExplicitStep(step) {
      let nextStep = INDEX_MAPPING[step]
      this.onSwitchToStep(nextStep)
    },
    onSwitchToStep(step) {
      let newStep = REVERSE_MAPPING[step]

      if (this.$router.currentRoute.name === newStep) return

      if (this.checkJumpCondition(newStep)) {
        this.$router.push({
          name: newStep
        })
        this.$refs.progressbar.updateProgressbar(INDEX_MAPPING[newStep])
      }
    }
  },
  async mounted() {
    let data = undefined
    try {
      data = (await this.$http.get(
          process.env.VUE_APP_BACKEND_ENDPOINT+'/api/products'
      ))['data']
    } catch (err) {
      this.$router.push({
        path: '/no-connection'
      })
    }
    if (!data) {
      this.$router.push({
        path: '/no-connection'
      })
    }

    await this.$store.dispatch('storeProductSpecification', data)

    this.thumbnailId = this.$store.getters.getHighestThumbnailId

    let current = this.$router.currentRoute.name
    this.$refs.progressbar.updateProgressbar(INDEX_MAPPING[current])

    let button = document.getElementsByClassName('bigstrip-order-page-bottom-button')[0]

    button.style.position = 'relative'
    button.style.margin = '30px 0px 15px 0px'
    button.style.bottom = '0px'

    document.addEventListener('contextmenu', function (e) {
      if (!process.env.VUE_APP_DEVELOPMENT) {
        e.preventDefault()
      }
    })
  }
}

</script>

<style scoped>

  .bigstrip-orderpage-container {
    width: 100%;
    height: 100%;
  }

  .bigstrip-orderpage-content {
    max-width: var(--bigstrip-size);
    background-color: var(--bigstrip-light-color);
    margin: 0px auto;
  }

  .bigstrip-order-page-bottom-button {
    width: 100%;
  }

</style>
