<template>
  <div id="BigstripFooter">

    <div class="bigstrip-footer-headlines">Zahlungsarten</div>
    <div>
      <img src="../../assets/common/payment/payment_methods.svg"/>
    </div>

    <div class="bigstrip-footer-headlines">Versandmethoden</div>
    <div>
      <img src="../../assets/common/deliver/delivery_methods.svg"/>
    </div>

    <ul>
      <li><span v-on:click="linkClicked('agb')">AGB</span></li>
      <li><span v-on:click="linkClicked('impressum')">Impressum</span></li>
      <li><span v-on:click="linkClicked('datenschutz')">Datenschutz</span></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BigstripMenu",
  methods: {
    linkClicked(redirect) {
      this.$router.push({
        name: redirect
      })
    }
  }
}
</script>

<style scoped>

  #BigstripFooter {
    color: var(--bigstrip-light-color);
    background: #28231C;
    border: none;
    border-top: 1px solid #707070;
    padding-top: 20px;
  }

  #BigstripFooter ul {
    margin-bottom: 0;
    margin-left: 20px;
    padding-bottom: 10px;
    list-style: none;
    list-style-type: none;
    text-align: left;
    list-style-position: unset;
  }

  #BigstripFooter li {
    color: white !important;
  }

  #BigstripFooter li:hover {
    text-decoration: underline;
  }

  .bigstrip-footer-headlines {
    padding-top: 30px;
    padding-bottom: 10px;
  }

</style>
