<template>
  <div>
    <div class="BigstripThumbnail">
      <img v-bind:src="src" v-bind:thumbnail="thumbnail" ref="thumbnail" style="width: 60%"/>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

let startY = 0

export default {
  name: "BigstripThumbnail",
  props: ['src', 'thumbnail', 'id'],
  mounted() {

    $(window).scroll(function () {
      startY = $(this).scrollTop()
    })

    let context = this
    let tn = this.$refs.thumbnail
    let dragging = false

    let dragStartListener = function (eve) {

      if (eve.changedTouches != undefined && eve.changedTouches.length == 0) {
        return
      }

      disableScroll()
      if (isMobile() || !dragging) {
        let x = isMobile() ? eve.changedTouches[0].clientX : eve.clientX
        let y = isMobile() ? eve.changedTouches[0].clientY : eve.clientY
        let dragContainer = document.getElementsByClassName('bigstrip-drag-and-drop-container')[0]
        dragContainer.style.display = 'block'
        dragContainer.style.top = (y+startY-dragContainer.clientHeight/2)+'px'
        dragContainer.style.left = (x-dragContainer.clientWidth/2)+'px'
        dragContainer.firstChild.src = tn.src
        dragging = true
      }
      eve.preventDefault()
    }

    let dragMoveListener = function (eve) {
      if (isMobile() || dragging) {
        let x = isMobile() ? eve.changedTouches[0].clientX : eve.clientX
        let y = isMobile() ? eve.changedTouches[0].clientY : eve.clientY
        let dragContainer = document.getElementsByClassName('bigstrip-drag-and-drop-container')[0]
        dragContainer.style.top = (y+startY-dragContainer.clientHeight/2)+'px'
        dragContainer.style.left = (x-dragContainer.clientWidth/2)+'px'
      }
      eve.preventDefault()
    }

    let dragEndListener = function (eve) {
      enableScroll()

      if (isMobile() && (eve.changedTouches === undefined && eve.changedTouches.length === 0)) {
        return
      }

      if (isMobile() || dragging) {
        if (isMobile() && (!eve.changedTouches || !eve.changedTouches[0])) return;
        let x = isMobile() ? eve.changedTouches[0].clientX : eve.clientX
        let y = isMobile() ? eve.changedTouches[0].clientY : eve.clientY
        let dragContainer = document.getElementsByClassName('bigstrip-drag-and-drop-container')[0]
        dragContainer.style.display = 'none'

        let dragContainers = document.getElementById('image_slots').getElementsByTagName('image')

        for (let n = 0; n < dragContainers.length; n++) {
          let container = dragContainers[n]
          if (isInside(x, y, container)) {
            context.$emit('draggedToSlot', {
              thumbnail: context.thumbnail,
              slot: n+1
            })
          }
        }
        dragging = false
      }
      eve.preventDefault()
    }

    if (isMobile()) {
      tn.addEventListener('touchstart', dragStartListener)
      tn.addEventListener('touchmove', dragMoveListener)
      tn.addEventListener('touchend', dragEndListener)
    } else {

      let body = document.getElementsByTagName('body')[0]

      tn.addEventListener('mousedown', dragStartListener)
      body.addEventListener('mousemove', dragMoveListener)
      body.addEventListener('mouseup', dragEndListener)
    }
  }
}

function isInside(x, y, slot) {
  let rect = slot.getBoundingClientRect()
  if ( x > rect.left &&
      x < rect.right &&
      y > rect.top &&
      y < rect.bottom) {
    return true
  } else {
    return false
  }
}

let supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; return 1}
  }));
} catch(e) {console.error(e)}

let wheelOpt = supportsPassive ? { passive: false } : false;
let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// call this to Disable
function disableScroll() {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  window.removeEventListener('touchmove', preventDefault, wheelOpt);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

function isMobile() {
  return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)
}

</script>

<style scoped>
  .BigstripThumbnail img, .BigstripThumbnailDrag img {
    -webkit-touch-callout: none;
    -webkit-user-select:none;
    -webkit-touch-callout: none;
  }
</style>
