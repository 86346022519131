<template>
  <div id="BigstripDragAndDrop">
    <img class="button-prev" src="../../../assets/orderpage/configurator/prev.svg"/>
    <div class="bigstrip-swiper" v-swiper:mySwiper="options">
      <div class="swiper-wrapper">
        <div v-for="(image, index) in this.getThumbnailes()" :key="image.src" :thumbnail="index" class="DraggableThumbnail swiper-slide">
          <bigstrip-thumbnail
              :thumbnail="image.thumbnail"
              :src="image.src"
              :size=30
              v-on:draggedToSlot="queryDraggedToSlot"
          />
        </div>
      </div>
    </div>
    <img class="button-next" src="../../../assets/orderpage/configurator/next.svg"/>
  </div>
</template>

<script>

import BigstripThumbnail from "@/components/orderpage/configurator/BigstripThumbnail";
import { directive } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import $ from 'jquery'

let activeLongPresses = {}
//let activeSlot = NOT_SET

export default {
  name: "BigstripDragAndDrop",
  components: {
    BigstripThumbnail,
  },
  data() {
    return {
      options: {
        direction: 'vertical',
        slidesPerView: 3,
        mousewheel: false,
        preventInteractionOnTransition: true,
        allowSlidePrev: true,
        allowSlideNext: true,
        keyboard: false,
        height: 1350,
        allowTouchMove: false,
        noSwiping: true
      }
    }
  },
  directives: {
    swiper: directive
  },
  mounted() {
    console.log(navigator.vendor)
    console.log(navigator.userAgent)
    if (this.isSafari()) {
      console.log('Safari was detected, fixing styling issues...')
      document.getElementById('BigstripDragAndDrop').classList.add('safari-fix')
    }

    activeLongPresses['context'] = this
    let slider = this.mySwiper
    $('.button-next').click(function () {
      slider.slideNext()
    })
    $('.button-prev').click(function () {
      slider.slidePrev()
    })
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    isSafari() {
      return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
          navigator.userAgent &&
          navigator.userAgent.indexOf('CriOS') == -1 &&
          navigator.userAgent.indexOf('FxiOS') == -1;
    },
    isMobile() {
      return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)/i)
    },
    queryDraggedToSlot(data) {

      let thumbnailData = this.$store.getters.getThumbnailDataById(data.thumbnail)
      let row = thumbnailData['row']
      let image = thumbnailData['thumbnail']

      //console.log('code: '+row['code']+', index:'+ image['index'])

      this.$emit(
          'addToSlot',
          {
            slot: data['slot'],
            code: row['code'],
            index: image['index']
          }
      )
    },
    forceRepaint() {
      console.log('Force repaint image slots')
      document.getElementById('slot_1').style.display='none'
      document.getElementById('slot_2').style.display='none'
      document.getElementById('slot_3').style.display='none'
      document.getElementById('slot_1').offsetHeight;
      document.getElementById('slot_2').offsetHeight;
      document.getElementById('slot_3').offsetHeight;
      setTimeout(() => {
        document.getElementById('slot_1').style.display='block'
        document.getElementById('slot_2').style.display='block'
        document.getElementById('slot_3').style.display='block'
      }, 50);

    },
    getThumbnailes() {
      let thumbnailes = this.$store.getters.getAvailableThumbnails()
      return thumbnailes
    }
  }
}

</script>



<style scoped>
  #BigstripDragAndDrop {
    background-color: var(--bigstrip-light-color);
    outline: none;
    overflow: hidden;
    z-index: 103;
    width: 100%;
    height: 100%;
  }

  .bigstrip-swiper {
    width: 100%;
    height: 86%;
  }

  .swiper-wrapper {
    margin-top: 8px;
  }

  .button-next, .button-prev {
    display: block;
    outline: none;
    height: 7%;
    width: 100%;
    background-color: #B0A682;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
  }

</style>
