<template>
  <div class="bigstrip-amount-chooser">
    <radio-button ref="amount1" v-on:stateChange="chooseAmount(1)" size="6vw"/>
    <div class="bigstrip-amount-chooser-label">1 Stück</div>
    <radio-button ref="amount2" v-on:stateChange="chooseAmount(2)" size="6vw" style="margin-left: 25px"/>
    <div class="bigstrip-amount-chooser-label">2 Stück</div>
    <div v-if="discountVisible" ref="discount" class="bigstrip-amount-chooser-discount"></div>
  </div>
</template>

<script>
import RadioButton from "@/components/common/RadioButton";

export default {
  name: "BigstripAmountChooser",
  components: {RadioButton},
  computed: {
    discountVisible() {
      return this.$store.getters.getAmount && this.$store.getters.getSize
    }
  },
  methods: {
    chooseAmount(amount) {
      this.$emit('chooseAmount', amount)
    },
    setAmount(amount) {
      if (amount === 1) {
        this.$refs.amount1.check()
        this.$refs.amount2.uncheck()
      }
      if (amount === 2) {
        this.$refs.amount1.uncheck()
        this.$refs.amount2.check()
      }
    },
    setDiscount(discount) {
      if (discount === 0) {
        this.$refs.discount.innerHTML = ''
      } else {
        this.$refs.discount.innerHTML = '- ' + discount + ' € Rabatt'
      }
    }
  }
}
</script>

<style scoped>

  .bigstrip-amount-chooser {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin: 15px 0px;
  }

  .bigstrip-amount-chooser-label {
    margin-left: 5px;
  }

  .bigstrip-amount-chooser-discount {
    font-family: "Neusa Next Std Bold";
    color: var(--bigstrip-color);
    margin-left: 10px;
  }

</style>