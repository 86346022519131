<template>
  <div id="BigstripCarouselWrapper">
    <carousel id="BigstripCarousel"
              :perPage="1"
              :loop="true"
              :autoplay="true"
              :autoplayTimeout="4000"
              paginationColor="#999999"
              paginationActiveColor="var(--bigstrip-color)">
      <slide v-for="page of pages" :key="page.key" class="bs-carousel-slide">
        <div>
          <img :src="page.background"/>
          <img style="top: 0; display: block; position:absolute;" :src="page.overlay"/>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: "BigstripCarousel",
  components: {
    Carousel,
    Slide
  },
  methods: {
    getStylingString (index) {
      return "position: absolute; left:" + index * 100 + "%;"
    }
  },
  data() {
    return {
      pages: [],

    }
  },
  mounted() {
    let context = this;
      this.$http.get(process.env.VUE_APP_BACKEND_ENDPOINT + '/api/carousel').then(function (response) {
        Object.keys(response.data).forEach((key) => {
          console.log(key)
          context.pages.push({
            key: key,
            background: 'data:image/jpeg;base64, ' + response.data[key].background,
            overlay: 'data:image/svg+xml;base64, '+ response.data[key].overlay
          })
        })
        console.log(context.pages)
      });
  }
}
</script>

<style scoped>

  #BigstripCarouselWrapper {
    pointer-events: none;
    margin: 0 auto;
    max-width: var(--bigstrip-size);
    width: 100%;
  }

  #BigstripCarousel {
    height: 30%;
    max-height: 300px;
    max-width: var(--bigstrip-size);
  }

  .bs-carousel-slide img {
    width: 100%;
    height: 100%;
  }

</style>
