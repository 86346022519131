<template>
  <div class="bigstrip-provider-choice">
    <div class="bigstrip-step-headline" style="text-transform: capitalize;">Zahlungsart</div>
    <div class="bigstrip-provider-choice-container">
      <svg version="1.1" id="Checkout-Layer" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
           y="0px" viewBox="0 0 283.5 283.5" style="enable-background:new 0 0 283.5 283.5;" xml:space="preserve">
      <path id="bg" class="st0" d="M119.2,90.2L119.2,90.2z"/>
        <g id="button_x5F_mastercard" v-on:click="chooseProvider('MasterCard')">
      <rect x="147.4" y="0" class="st1" width="136.1" height="136.1"/>
          <path class="st2" d="M215.6,87.7c4.8,4.4,11.1,6.9,17.6,6.8c14.6-0.1,26.3-12,26.2-26.6c-0.1-14.4-11.8-26.1-26.2-26.2
        c-6.5,0-12.8,2.4-17.6,6.8c-10.8,9.8-11.6,26.5-1.8,37.3C214.4,86.5,215,87.1,215.6,87.7z"/>
          <path class="st3" d="M224.1,65.3c-0.1-0.9-0.3-1.9-0.5-2.8h-16c0.2-0.9,0.4-1.9,0.7-2.8h14.5c-0.3-1-0.7-1.9-1.1-2.8h-12.3
        c0.5-1,1-1.9,1.5-2.8h9.3c-0.6-1-1.3-1.9-2-2.8h-5.3c0.8-1,1.7-1.9,2.7-2.8c-10.8-9.8-27.5-9-37.3,1.8s-9,27.5,1.8,37.3
        c10.1,9.1,25.4,9.1,35.5,0c0.9-0.9,1.8-1.8,2.6-2.8h-5.3c-0.7-0.9-1.4-1.8-2-2.8h9.3c0.6-0.9,1.1-1.8,1.5-2.8h-12.3
        c-0.4-0.9-0.8-1.8-1.1-2.8h14.5c0.3-0.9,0.6-1.9,0.8-2.8s0.4-1.9,0.5-2.8s0.1-1.8,0.1-2.8C224.2,67.2,224.2,66.2,224.1,65.3
        L224.1,65.3z"/>
          <path class="st4" d="M206.7,75.7c-0.7,0.2-1.4,0.3-2.1,0.4c-1.4,0-2.2-0.9-2.2-2.4c0-0.3,0-0.7,0.1-1l0.2-1l0.1-0.9l1.2-7.4h2.7
        l-0.3,1.6h1.7l-0.4,2.7H206l-0.7,4.4c0,0.1,0,0.3,0,0.5c0,0.6,0.3,0.8,0.9,0.8c0.3,0,0.6,0,0.9-0.1L206.7,75.7z M215.5,75.6
        c-0.9,0.3-1.9,0.4-2.9,0.4c-3.1,0-4.8-1.6-4.8-4.8c0-3.8,2.1-6.5,5-6.5c2-0.1,3.7,1.4,3.8,3.4c0,0.2,0,0.3,0,0.5
        c0,0.9-0.1,1.8-0.3,2.7h-5.7c0,0.1,0,0.2,0,0.4c0,1.3,0.9,1.9,2.5,1.9c1,0,2-0.2,2.9-0.7L215.5,75.6z M213.8,69.2v-0.5
        c0.1-0.7-0.4-1.3-1.1-1.4c-0.1,0-0.2,0-0.3,0c-0.9,0-1.5,0.7-1.8,1.9H213.8L213.8,69.2z M184.9,75.9h-2.8l1.6-10.3L180,75.9h-2
        l-0.2-10.2l-1.7,10.2h-2.8l2.2-13.4h4.1l0.1,8.2l2.8-8.2h4.5L184.9,75.9z M191.8,71c-0.2,0-0.4,0-0.5,0c-1.6,0-2.4,0.6-2.4,1.6
        c-0.1,0.6,0.3,1.1,0.9,1.1h0.1C191.2,73.7,191.7,72.6,191.8,71z M194,75.9h-2.4v-1.1c-0.7,0.9-1.8,1.4-3,1.3
        c-1.5-0.1-2.7-1.4-2.5-2.9c0,0,0,0,0-0.1c0-0.5,0.1-0.9,0.2-1.4c0.5-1.7,2.1-2.7,4.7-2.8c0.3,0,0.8,0,1.3,0
        c0.1-0.2,0.1-0.5,0.1-0.7c0-0.7-0.6-0.9-1.9-0.9c-0.8,0-1.6,0.1-2.3,0.4l-0.4,0.1h-0.2l0.4-2.4c1.1-0.4,2.2-0.6,3.3-0.6
        c2.4,0,3.8,1.1,3.8,3.2c0,0.7,0,1.4-0.1,2.1l-0.6,3.9l-0.1,0.7v0.5v0.4L194,75.9L194,75.9z M229.2,65.1c0.9,0,1.8,0.3,2.5,0.7
        l0.5-2.9c-0.2-0.1-0.5-0.2-0.7-0.3l-1.1-0.3c-1.5-0.3-3-0.1-4.4,0.4c-0.6,0.4-1.2,0.9-1.7,1.4l-0.4-0.1l-2.9,2l0.1-1.1h-3
        l-1.8,10.9h2.9l1-5.8c0,0,0.4-0.8,0.6-1.1c0.4-0.5,1-0.8,1.6-0.7h0.2c-0.1,0.7-0.1,1.4-0.1,2.1c0,3.5,2,5.8,5.1,5.8
        c0.8,0,1.7-0.1,2.5-0.4l0.5-3.1c-0.7,0.4-1.6,0.7-2.4,0.7c-1.7,0-2.7-1.3-2.7-3.3C225.5,67.2,227,65.1,229.2,65.1L229.2,65.1z
         M253.2,62.5l-0.6,3.8c-0.5-1-1.5-1.5-2.6-1.5c-1.6,0-3.1,1-3.8,2.5l0,0l-1.8-1.1l0.2-1.1h-3L239.9,76h2.8l0.9-5.8
        c0,0,0.7-0.8,0.9-1.1c0.3-0.4,0.8-0.6,1.3-0.7c-0.3,1-0.5,2.1-0.5,3.2c0,2.7,1.4,4.5,3.5,4.5c1,0,2-0.4,2.6-1.2l-0.1,1.1h2.7
        l2.2-13.4L253.2,62.5L253.2,62.5z M249.7,73.3c-0.9,0-1.5-0.7-1.5-2.1c0-2.1,0.9-3.7,2.2-3.7c1,0,1.5,0.8,1.5,2.1
        C252,71.8,251.1,73.3,249.7,73.3z M236.6,71c-0.2,0-0.4,0-0.5,0c-1.6,0-2.4,0.6-2.4,1.6c-0.1,0.6,0.3,1.1,0.9,1.1h0.1
        C236.1,73.7,236.6,72.6,236.6,71z M238.9,75.9h-2.5l0.1-1.1c-0.7,0.9-1.8,1.4-3,1.3c-1.5-0.1-2.7-1.4-2.6-2.9v-0.1
        c0-2.6,1.9-4.2,5-4.2c0.4,0,0.8,0,1.2,0c0.1-0.2,0.1-0.5,0.1-0.7c0-0.7-0.6-0.9-1.9-0.9c-0.8,0-1.6,0.1-2.4,0.4l-0.4,0.1h-0.2
        l0.4-2.4c1.1-0.4,2.2-0.6,3.3-0.6c2.4,0,3.7,1.1,3.7,3.2c0.1,0.7,0,1.4-0.1,2.1L239,74l-0.1,0.7l-0.1,0.5v0.4L238.9,75.9
        L238.9,75.9L238.9,75.9z M200,67.3c0.7,0,1.4,0.1,2.1,0.2l0.4-2.5c-0.8-0.1-1.9-0.2-2.5-0.2c-3.2,0-4.2,1.7-4.2,3.7
        c0,1.3,0.6,2.3,2.1,3c1.1,0.5,1.3,0.6,1.3,1.1c0,0.7-0.6,1.1-1.7,1.1c-0.9,0-1.8-0.2-2.6-0.5l-0.3,2.5l0,0l0.5,0.1
        c0.2,0.1,0.5,0.1,0.7,0.1c0.7,0,1.3,0.1,1.6,0.1c3.2,0,4.5-1.2,4.5-3.6c0-1.4-0.8-2.6-2.1-3c-1.2-0.5-1.4-0.6-1.4-1.1
        S199.1,67.3,200,67.3z"/>
          <path class="st0" d="M233,62.1l-0.5,2.9c-0.8-0.4-1.6-0.7-2.5-0.7c-2.1,0-3.6,2.1-3.6,5c0,2,1,3.3,2.7,3.3c0.8,0,1.7-0.3,2.4-0.7
        l-0.5,3c-0.8,0.2-1.6,0.4-2.5,0.4c-3.1,0-5-2.2-5-5.8c0-4.8,2.6-8.1,6.4-8.1c0.4,0,0.9,0,1.3,0.1l1.1,0.3
        C232.6,61.9,232.7,62,233,62.1z M223.8,64h-0.3c-0.9,0-1.5,0.5-2.4,1.8l0.3-1.7h-2.6L217.1,75h2.9c1-6.7,1.3-7.8,2.7-7.8h0.2
        C223,66.1,223.4,65.1,223.8,64L223.8,64L223.8,64z M207.4,74.9c-0.7,0.2-1.3,0.4-2,0.4c-1.5,0-2.3-0.8-2.3-2.4c0-0.3,0-0.6,0.1-0.9
        l0.2-1.1l0.1-0.9l1.2-7.4h2.8l-0.3,1.6h1.5l-0.4,2.7h-1.4l-0.8,4.5c0,0.1-0.1,0.3,0,0.5c0,0.5,0.3,0.8,0.9,0.8c0.3,0,0.5,0,0.8-0.1
        L207.4,74.9z M196.5,67.6c0,1.4,0.8,2.6,2.1,3c1.2,0.5,1.4,0.7,1.4,1.2c0,0.7-0.5,1-1.6,1c-0.9,0-1.7-0.1-2.5-0.4l-0.4,2.5h0.1
        L196,75c0.3,0.1,0.5,0.1,0.8,0.1c0.5,0.1,1,0.1,1.5,0.1c3,0,4.4-1.1,4.4-3.6c0.1-1.3-0.7-2.6-2-3c-1.2-0.5-1.4-0.7-1.4-1.2
        c0-0.6,0.5-0.9,1.4-0.9c0.7,0,1.4,0.1,2,0.1l0.4-2.5c-0.8-0.1-1.7-0.2-2.5-0.2C197.6,63.9,196.4,65.6,196.5,67.6L196.5,67.6z
         M254.7,75H252l0.1-1c-0.7,0.8-1.6,1.2-2.6,1.2c-2.1,0-3.4-1.8-3.4-4.5c0-3.6,2.1-6.7,4.6-6.7c1.1,0,2.1,0.6,2.7,1.5l0.6-3.8h2.8
        L254.7,75L254.7,75z M250.5,72.5c1.3,0,2.2-1.5,2.2-3.7c0-1.4-0.5-2.1-1.5-2.1c-1.3,0-2.2,1.5-2.2,3.6
        C249,71.7,249.5,72.5,250.5,72.5L250.5,72.5z M216.1,74.8c-1,0.3-1.9,0.5-2.9,0.5c-3.2,0-4.8-1.7-4.8-4.9c0-3.7,2.1-6.5,4.9-6.5
        c2-0.1,3.7,1.4,3.9,3.4c0,0.2,0,0.4,0,0.5c0,0.9-0.1,1.8-0.4,2.7h-5.6c0,0.1,0,0.2,0,0.3c0,1.3,0.9,1.9,2.5,1.9c1,0,2-0.2,2.9-0.7
        L216.1,74.8z M214.5,68.3v-0.5c0.1-0.7-0.4-1.3-1.1-1.4c-0.1,0-0.2,0-0.3,0c-0.9,0-1.5,0.7-1.8,1.9H214.5z M185.7,75h-2.8l1.6-10.3
        L180.8,75h-1.9l-0.2-10.2L176.9,75h-2.6l2.2-13.4h4.1l0.1,8.3l2.7-8.3h4.4L185.7,75z M192.7,70.2c-0.3,0-0.4,0-0.6,0
        c-1.6,0-2.4,0.6-2.4,1.7c-0.1,0.5,0.3,1,0.8,1.1c0.1,0,0.1,0,0.2,0C191.8,72.9,192.6,71.8,192.7,70.2L192.7,70.2z M194.8,75h-2.4
        v-1.1c-0.7,0.9-1.8,1.4-3,1.3c-1.5,0-2.6-1.2-2.6-2.9c0-2.7,1.8-4.2,5-4.2c0.3,0,0.7,0,1.2,0.1c0.1-0.2,0.1-0.4,0.1-0.7
        c0-0.7-0.5-1-1.8-1c-0.8,0-1.6,0.1-2.4,0.3l-0.4,0.1h-0.3l0.4-2.4c1.1-0.4,2.2-0.5,3.4-0.5c2.4,0,3.8,1.1,3.8,3.2
        c0,0.7-0.1,1.5-0.2,2.2l-0.6,3.9l-0.1,0.7v0.5L194.8,75L194.8,75L194.8,75z M237.5,70.2c-0.3,0-0.5,0-0.6,0c-1.6,0-2.4,0.6-2.4,1.7
        c-0.1,0.5,0.3,1,0.9,1.1c0.1,0,0.1,0,0.2,0C236.6,72.9,237.5,71.8,237.5,70.2z M239.6,75h-2.4v-1.1c-0.7,0.9-1.8,1.4-3,1.3
        c-1.5,0-2.6-1.2-2.6-2.9c0-2.7,1.8-4.2,5-4.2c0.3,0,0.7,0,1.1,0.1c0.1-0.2,0.1-0.4,0.1-0.7c0-0.7-0.5-1-1.8-1
        c-0.8,0-1.6,0.1-2.4,0.3l-0.4,0.1h-0.3l0.4-2.4c1.1-0.4,2.2-0.5,3.4-0.5c2.4,0,3.7,1.1,3.7,3.2c0,0.7-0.1,1.5-0.2,2.2l-0.6,3.9
        l-0.1,0.7l-0.1,0.5v0.4L239.6,75L239.6,75L239.6,75z M247.2,64H247c-0.9,0-1.5,0.5-2.4,1.8l0.3-1.7h-2.6L240.6,75h2.8
        c1-6.7,1.3-7.8,2.7-7.8h0.2C246.4,66.1,246.8,65.1,247.2,64L247.2,64z"/>
          <path class="st5" d="M255.9,82.8c0-0.4,0.4-0.8,0.8-0.8l0,0c0.5,0,0.8,0.3,0.9,0.8l0,0c0,0.5-0.3,0.8-0.8,0.9c0,0,0,0-0.1,0
        C256.3,83.7,255.9,83.3,255.9,82.8L255.9,82.8z M256.8,83.5c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.6-0.7-0.6c-0.3,0-0.6,0.3-0.6,0.6
        C256.1,83.2,256.4,83.5,256.8,83.5z M256.7,83.2h-0.2v-0.8h0.3c0.1,0,0.1,0,0.2,0s0.1,0.1,0.1,0.2s0,0.2-0.1,0.2l0.2,0.4H257
        l-0.1-0.3h-0.1v-0.1h0.2c0,0,0,0,0-0.1s0,0,0-0.1h-0.2L256.7,83.2z"/>
          <path class="st0" d="M255.9,74.2c0-0.5,0.3-0.8,0.8-0.9l0,0c0.5,0,0.8,0.3,0.9,0.8c0,0,0,0,0,0.1c0,0.5-0.3,0.8-0.8,0.9
        C256.4,75.1,256,74.8,255.9,74.2C255.9,74.3,255.9,74.3,255.9,74.2L255.9,74.2z M256.8,74.9c0.4,0,0.7-0.2,0.7-0.6
        s-0.2-0.7-0.6-0.7h-0.1c-0.4,0-0.6,0.3-0.6,0.6S256.4,74.9,256.8,74.9z M256.7,74.6h-0.2v-0.7h0.5c0.1,0,0.1,0.1,0.1,0.2
        s-0.1,0.1-0.1,0.2l0.2,0.3H257l-0.1-0.3h-0.1v-0.1h0.1h0.1c0,0,0,0,0-0.1l0,0l0,0c0,0,0,0-0.1,0h-0.1L256.7,74.6z"/>
    </g>
        <g id="button_x5F_visa" v-on:click="chooseProvider('VISA')">
      <rect y="0" class="st1" width="136.1" height="136.1"/>
          <path class="st6" d="M54.8,81.5l4.5-26.8h7.3L62,81.5H54.8z"/>
          <path class="st6" d="M88.5,55.3c-2.1-0.8-4.3-1.1-6.5-1.1c-7.2,0-12.4,3.7-12.4,8.9c-0.1,3.8,3.7,6,6.4,7.3
        c2.8,1.3,3.8,2.2,3.8,3.3c0,1.8-2.3,2.6-4.4,2.6c-2.4,0.1-4.7-0.4-6.9-1.4l-1-0.4l-1,6c2.6,1,5.4,1.5,8.2,1.5
        c7.8,0,12.7-3.6,12.7-9.1c0.1-3-1.9-5.4-6.1-7.3c-2.6-1.2-4.1-2.1-4.1-3.3c0-1.1,1.3-2.4,4.2-2.4c1.8-0.1,3.7,0.3,5.5,1l0.7,0.3
        L88.5,55.3"/>
          <path class="st6" d="M107.3,54.7h-5.7c-1.7,0-3,0.4-3.8,2.2L87,81.5h7.7c0,0,1.2-3.3,1.6-4h9.3c0.2,1,0.9,4,0.9,4h6.8L107.3,54.7
         M98.3,72c0.6-1.6,3-7.5,3-7.5c-0.1,0.1,0.6-1.6,1-2.6l0.5,2.4c0,0,1.4,6.4,1.7,7.8H98.3z"/>
          <path class="st6" d="M48.6,54.7L41.3,73l-0.8-3.7c-1.2-4.4-5.4-9-10-11.3l6.5,23.5h7.8l11.5-26.8H48.6"/>
          <path class="st7" d="M34.8,54.7H23l-0.1,0.5c9.2,2.2,15.2,7.6,17.8,14L38,56.8C37.7,55.2,36.4,54.7,34.8,54.7"/>
    </g>
        <g id="button_x5F_klarna" v-on:click="chooseProvider('Klarna')">
      <rect y="147.4" class="st1" width="136.1" height="136.1"/>
          <path class="st6" d="M83.7,211.7h-0.5l-1,2.6h0.6c0.9,0.1,1.7-0.5,2-1.4C85.1,212,84.7,211.7,83.7,211.7z"/>
          <path class="st6" d="M74.6,211.8c-1.1,0-1.9,1.2-2.6,3.1c-0.9,2.5-0.6,3.1,0.4,3.1s1.7-0.6,2.6-3.1S75.7,211.8,74.6,211.8
        L74.6,211.8z"/>
          <path class="st6" d="M100.9,187.5H35.1c-2.8,0-5.1,2.3-5.1,5.1v45.6c0,2.8,2.3,5.1,5.1,5.1h65.8c2.8,0,5.1-2.3,5.1-5.1v-45.6
        C106,189.7,103.7,187.5,100.9,187.5z M49.9,216.4c-0.3,1.5-1.3,2.7-2.6,3.3c-0.9,0.4-1.9,0.5-2.9,0.5h-6.8l1-2.7h6.3
        c0.4,0,0.7,0,1.1-0.1c0.2-0.1,0.4-0.3,0.4-0.5c0-0.1,0-0.2,0-0.3s0-0.2-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1
        l-0.6-0.2l-0.4-0.1c-0.3-0.1-0.6-0.3-0.9-0.5c-0.2-0.2-0.5-0.4-0.6-0.7c-0.1-0.2-0.2-0.5-0.1-0.7c0-0.3,0.1-0.6,0.2-1
        c0.6-1.7,1.3-2.6,2.2-3s1.8-0.5,2.7-0.4h6.1c-1,0.6-1.9,1.4-2.5,2.4l-0.1,0.1h-3.9c-0.2,0-0.5,0-0.7,0.2c-0.2,0.1-0.3,0.2-0.3,0.4
        s0,0.3,0.1,0.5c0.1,0.1,0.2,0.3,0.4,0.3l0.5,0.2l0.4,0.1c0.6,0.1,1.2,0.5,1.5,1C50,215.2,50.1,215.8,49.9,216.4L49.9,216.4z
         M54,220.4L54,220.4c-1.1,0.1-2.1-0.3-2.8-1.2c-0.7-1-0.7-2.4,0-4.2c0.3-0.9,0.8-1.8,1.3-2.5c0.7-1.1,1.7-1.9,2.9-2.5
        c0.7-0.4,1.6-0.5,2.4-0.5c1-0.1,2.1,0.3,2.7,1.2c0.9,1.3,0.4,3.1,0,4.1C59.1,218.4,56.9,220.4,54,220.4L54,220.4z M66.9,216H64
        l-1.5,4.2h-3.2l3-8.1c0.5-1.6,2-2.6,3.7-2.5h6.2c-1,0.6-2,1.4-2.7,2.4l-0.1,0.1H66c-0.3,0-0.5,0.2-0.7,0.4l-0.5,1.3h2.9L66.9,216
        L66.9,216z M71.7,220.4L71.7,220.4c-1.1,0.1-2.1-0.3-2.8-1.2c-0.7-1-0.7-2.4,0-4.2c0.3-0.9,0.8-1.8,1.3-2.5
        c0.7-1.1,1.7-1.9,2.9-2.5c0.7-0.4,1.6-0.5,2.4-0.5c1-0.1,2.1,0.3,2.7,1.2c0.9,1.3,0.4,3.1,0,4.1C76.8,218.4,74.6,220.4,71.7,220.4
        L71.7,220.4z M84.7,215.6c0.2,0.3,0.3,0.5,0.4,0.8c0.4,1.2,0.8,2.5,1.1,3.7h-3.5c0,0-0.8-3.6-0.9-3.8l-1.4,3.8h-3.3l3.9-10.7h3.6
        c1.6,0,2.5,0.3,3,1c0.4,0.7,0.4,1.6,0.1,2.3C87.1,214.2,86,215.2,84.7,215.6L84.7,215.6z M97.5,212.1h-3.8l-3,8.1h-3l3-8.1h-2
        c0.1-0.7-0.1-1.4-0.4-2c-0.1-0.2-0.3-0.4-0.5-0.6h10.6L97.5,212.1L97.5,212.1z"/>
          <path class="st6" d="M56.9,211.8c-1.1,0-1.9,1.2-2.6,3.1c-0.9,2.5-0.6,3.1,0.4,3.1s1.7-0.6,2.6-3.1S58,211.8,56.9,211.8z"/>
    </g>
        <g id="button_x5F_paypal" transform="translate(0)" v-on:click="chooseProvider('PayPal')">
      <rect x="147.4" y="147.4" class="st1" width="136.1" height="136.1"/>
          <path class="st6" d="M203.5,213.9c-0.3,2-1.8,2-3.3,2h-0.8l0.6-3.7c0-0.2,0.2-0.4,0.5-0.4h0.4c1,0,1.9,0,2.4,0.6
        C203.5,212.8,203.6,213.3,203.5,213.9z M202.8,208.7h-5.5c-0.4,0-0.7,0.3-0.8,0.7l-2.2,14.2c0,0.3,0.1,0.5,0.4,0.5c0,0,0,0,0.1,0
        h2.7c0.4,0,0.7-0.3,0.8-0.7l0.6-3.8c0.1-0.4,0.4-0.6,0.7-0.7h1.7c3.6,0,5.8-1.8,6.3-5.3c0.3-1.2,0-2.5-0.7-3.6
        C206.1,209.2,204.7,208.7,202.8,208.7z"/>
          <path class="st6" d="M215.7,218.9c-0.2,1.5-1.5,2.6-3,2.5c-0.7,0-1.3-0.2-1.8-0.7c-0.4-0.5-0.6-1.2-0.4-1.9c0.2-1.5,1.5-2.6,3-2.6
        c0.7,0,1.3,0.2,1.8,0.7C215.6,217.6,215.8,218.3,215.7,218.9z M219.4,213.8h-2.7c-0.2,0-0.4,0.2-0.5,0.4l-0.1,0.7l-0.2-0.3
        c-0.6-0.8-1.9-1.1-3.1-1.1c-3,0.1-5.5,2.3-5.9,5.3c-0.3,1.4,0.1,2.9,1,4.1c0.9,0.9,2.1,1.4,3.3,1.3c1.4,0,2.7-0.5,3.7-1.5l-0.1,0.7
        c0,0.3,0.1,0.5,0.4,0.5c0,0,0,0,0,0h2.4c0.4,0,0.7-0.3,0.8-0.7l1.4-9.1C219.9,214.1,219.7,213.8,219.4,213.8
        C219.4,213.8,219.4,213.8,219.4,213.8L219.4,213.8z"/>
          <path class="st6" d="M233.5,213.8h-2.7c-0.3,0-0.5,0.1-0.6,0.3l-3.7,5.4l-1.6-5.2c-0.1-0.3-0.4-0.5-0.7-0.5h-2.6
        c-0.3,0-0.5,0.2-0.5,0.5c0,0.1,0,0.1,0,0.2l2.9,8.6l-2.8,3.9c-0.1,0.2-0.1,0.5,0.1,0.6c0.1,0.1,0.2,0.1,0.3,0.1h2.7
        c0.3,0,0.5-0.1,0.6-0.3l8.9-12.8c0.1-0.2,0.1-0.5-0.1-0.6C233.6,213.8,233.6,213.8,233.5,213.8z"/>
          <path class="st6" d="M242.9,213.9c-0.3,2-1.8,2-3.3,2h-0.8l0.6-3.7c0-0.2,0.2-0.4,0.5-0.4h0.4c1,0,1.9,0,2.4,0.6
        C243,212.8,243.1,213.3,242.9,213.9z M242.3,208.7h-5.5c-0.4,0-0.7,0.3-0.7,0.7l-2.2,14.2c0,0.3,0.1,0.5,0.4,0.5c0,0,0,0,0.1,0h2.8
        c0.3,0,0.5-0.2,0.5-0.5l0.6-4c0.1-0.4,0.4-0.6,0.7-0.7h1.7c3.6,0,5.8-1.8,6.3-5.3c0.3-1.2,0-2.5-0.7-3.6
        C245.5,209.2,244.1,208.7,242.3,208.7z"/>
          <path class="st6" d="M255.1,218.9c-0.2,1.5-1.5,2.6-3,2.5c-0.7,0-1.3-0.2-1.8-0.7c-0.4-0.5-0.6-1.2-0.4-1.9c0.2-1.5,1.5-2.6,3-2.5
        c1.2-0.1,2.2,0.8,2.3,1.9C255.2,218.5,255.2,218.7,255.1,218.9z M258.8,213.8h-2.7c-0.2,0-0.4,0.2-0.5,0.4l-0.1,0.7l-0.2-0.3
        c-0.6-0.8-1.9-1.1-3.1-1.1c-3,0.1-5.5,2.3-5.9,5.3c-0.3,1.4,0.1,2.9,1,4.1c0.9,0.9,2.1,1.4,3.3,1.3c1.4,0,2.7-0.5,3.7-1.5l-0.1,0.7
        c0,0.3,0.1,0.5,0.4,0.5c0,0,0,0,0,0h2.4c0.4,0,0.7-0.3,0.7-0.7l1.4-9.1C259.3,214.1,259.1,213.8,258.8,213.8
        C258.9,213.8,258.8,213.8,258.8,213.8L258.8,213.8z"/>
          <path class="st6" d="M261.9,209.1l-2.3,14.5c0,0.3,0.1,0.5,0.4,0.5c0,0,0,0,0.1,0h2.3c0.4,0,0.7-0.3,0.8-0.7l2.2-14.2
        c0-0.3-0.1-0.5-0.4-0.5c0,0,0,0,0,0h-2.6C262.2,208.7,262,208.8,261.9,209.1z"/>
          <path class="st7" d="M184.1,209.4c0,0.1,0,0.3-0.1,0.5c-1,5.1-4.4,6.9-8.8,6.9H173c-0.5,0-1,0.4-1.1,0.9l-1.1,7.2l-0.3,2
        c0,0.3,0.2,0.6,0.5,0.7c0,0,0.1,0,0.1,0h3.9c0.5,0,0.9-0.3,0.9-0.8l0-0.2l0.7-4.7l0-0.2c0.1-0.5,0.5-0.8,0.9-0.8h0.6
        c3.8,0,6.8-1.6,7.7-6.1c0.4-1.9,0.2-3.4-0.8-4.6C184.8,209.9,184.5,209.6,184.1,209.4z"/>
          <path class="st8" d="M183,209c-0.2,0-0.3-0.1-0.5-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.7-0.1-1.3-0.1-2-0.1h-5.9c-0.1,0-0.3,0-0.4,0.1
        c-0.3,0.1-0.5,0.4-0.5,0.7l-1.3,8l0,0.2c0.1-0.5,0.5-0.9,1.1-0.9h2.2c4.4,0,7.8-1.8,8.8-6.9c0-0.2,0-0.3,0.1-0.5
        c-0.3-0.1-0.5-0.3-0.8-0.3C183.2,209,183.1,209,183,209z"/>
          <path class="st6" d="M173.2,209.5c0-0.3,0.2-0.6,0.5-0.7c0.1-0.1,0.3-0.1,0.4-0.1h5.9c0.7,0,1.3,0,2,0.1c0.2,0,0.3,0.1,0.5,0.1
        c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.2,0.1c0.3,0.1,0.6,0.2,0.8,0.3c0.3-1.9,0-3.2-1-4.4c-1.1-1.3-3.2-1.8-5.8-1.8h-7.6
        c-0.5,0-1,0.4-1.1,0.9l-3.2,20.1c-0.1,0.4,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0h4.7l1.2-7.5L173.2,209.5z"/>
    </g>
    </svg>
    </div>

    <!--paypal-provider
        amount="10.00"
        currency="USD"
        v-on:payment-authorized="paymentAuthorized"
        v-on:payment-completed="paymentCompleted"
        v-on:payment-cancelled="paymentCancelled"
        :client="paypal"
        env="sandbox">
    </--paypal-provider>
    <div class="ButtonCreditCard">
      <button v-on:click="prepareCheckout">Kreditkarte</button>
    </div>
    <BigstripKlarna ref="klarna" /-->
  </div>
</template>

<script>
export default {
  name: "BigstripProviderChoice",
  components: {
  },
  data() {
    return {
      paypal: {
        "sandbox": "AZTClUOaxmxvoqmJE0quFpU1cJeffRxIc4zwKMZ4TclxGQs9MT493OaBjXHUSbgG6sN0NE2ONfzKXnEr",
        production: ""
      },
    }
  },
  methods: {
    async chooseProvider(provider) {
      await this.$store.dispatch('setProvider', provider)
      await this.$emit('switchToNextStep')
    }
  },
  mounted() {
    this.$emit('setupButton', {
      text: '',
      visible: false
    })
  }
}
</script>

<style scoped>

.bigstrip-provider-choice-container {
  margin: 15px;
}

.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
.st1{fill:#EBEBEB;}
.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#CCCCCC;}
.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#CC0099;}
.st4{fill-rule:evenodd;clip-rule:evenodd;fill:#28231C;}
.st5{fill-rule:evenodd;clip-rule:evenodd;fill:#E9B040;}
.st6{fill:#CC0099;}
.st7{fill:#CCCCCC;}
.st8{fill:#991F7A;}

</style>
