<template>
  <div id="BigstripConfigurator">
    <div class="bigstrip-drag-and-drop-container">
      <img src="" alt="Lädt...">
    </div>
    <div class="bigstrip-step-headline">Konfigurator</div>
    <div class="bigstrip-step-description">Ziehe dir deine Fotos per Drag and Drop auf deinen Streifen um deinen B!G STRIP zu erstellen.</div>

    <div class="bigstrip-configurator-grid-container">
      <div class="bigstrip-configurator-grid-main">
        <div id="BigstripConfiguratorContainer">
          <div class="bigstrip-configurator-background-overlay">
            <svg version="1.1" id="configurator_svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 1394.6 1569.6" style="enable-background:new 0 0 1394.6 1569.6;" xml:space="preserve">
          <foreignObject class="bigstrip-embedded-swiper st0" x="80" y="0" width="480" height="1569.6">
            <div class="bigstrip-embedded-swiper-container">
                <bigstrip-drag-and-drop
                    @addToSlot="onAddToSlot"
                    ref="swiper"
                />
            </div>
          </foreignObject>
            <g id="image_slots">
              <image id="slot_1" x="830.3" y="175.5" class="st0" width="265.6" height="400.4"/>
              <image id="slot_2" x="830.3" y="575.9" class="st0" width="265.6" height="400.4"/>
              <image id="slot_3" x="830.3" y="976.3" class="st0" width="265.6" height="400.4"/>
            </g>
        </svg>
          </div>
        </div>
      </div>
      <div v-if="overlayVisible" class="bigstrip-configurator-grid-info-overlay">
        <div class="bigstrip-configurator-info-overlay-container" v-on:click="overlayVisible = false">
          <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 1394.7 1569.7" style="enable-background:new 0 0 1394.7 1569.7;" xml:space="preserve">
            <g transform="translate(15.571 -49.308) rotate(30)">
              <path class="st5" d="M795.9-74.9l0,215.3L950.5,32.7L795.9-74.9z"/>
              <path class="st5" d="M731.2,8.2c-3.5,0.9-7.1,1.6-10.6,2.7L710,14.6c-28.2,9-54.7,22.9-78,41.1c-23,17.8-41.6,40.7-54.4,66.9
                c-12.6,24.9-18.5,52.6-17.1,80.4c4.5-26,15.7-50.4,32.6-70.8c15.7-19.5,35.8-35.2,58.5-45.8c22.2-10.4,46.1-16.6,70.5-18.5l9.1-1
                c3-0.3,6.2-0.1,9.3-0.2l18.4-0.2c12.3,0.3,24.9,1.8,37,2.7l0-72.9c-14.6,2.2-29,3.9-43.3,6.8L731.2,8.2z"/>
            </g>
            <rect x="653.9" y="518.3" class="st1" width="625.6" height="256.5"/>
            <rect x="580.9" y="1397.8" class="st1" width="610.4" height="139.3"/>
            <text transform="matrix(1 0 0 1 675.001 600.718)"><tspan x="0" y="0" class="st2 st3 st4">Ziehe die Fotos einfach</tspan><tspan x="0" y="68.4" class="st2 st3 st4">per Drag &amp; Drop an</tspan><tspan x="0" y="136.8" class="st2 st3 st4">die gewünschte Stelle</tspan></text>
            <text transform="matrix(1 0 0 1 805.6719 1457.1232)"><tspan x="0" y="0" class="st2 st3 st4">Hier kannst du</tspan><tspan x="-212.7" y="68.4" class="st2 st3 st4">die Fotos zurücksetzen</tspan></text>
          </svg>
        </div>
      </div>
      <div class="bigstrip-configurator-grid-help noselect" v-on:click="toggleInfoOverlay">
        <span class="bigstrip-configurator-help-text">Hilfe</span>
        <img class="bigstrip-configurator-help" src="../../assets/orderpage/configurator/help.svg" />
      </div>
      <div class="bigstrip-configurator-grid-reset">
        <img
            class="bigstrip-configurator-remove-all-button"
            src="../../assets/orderpage/configurator/remove_all.svg"
            v-on:click="removeAll"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BigstripDragAndDrop from "./configurator/BigstripDragAndDrop";
import $ from "jquery";

export default {
  name: "BigstripConfigurator",
  components: {
    BigstripDragAndDrop,
  },
  data() {
    return {
      overlayVisible: {Type: Boolean, default: true}
    }
  },
  methods: {
    async onAddToSlot(data) {

      await this.storeSlotData(data)

      this.update()
      this.$emit('saveConfig')
      this.$emit('validate', this.checkIfFull())

    },
    async storeSlotData(slotData) {
      console.log('Storing into slot '+slotData['slot'])
      if (slotData['slot'] === 1) await this.$store.dispatch('setSlot1', slotData)
      if (slotData['slot'] === 2) await this.$store.dispatch('setSlot2', slotData)
      if (slotData['slot'] === 3) await this.$store.dispatch('setSlot3', slotData)
    },
    async removeSlotData(slot) {
      console.log('Removing from slot '+slot)
      if (slot === 1) await this.$store.dispatch('removeSlot1')
      if (slot === 2) await this.$store.dispatch('removeSlot2')
      if (slot === 3) await this.$store.dispatch('removeSlot3')
    },
    async onMoveToSlot(data) {
      if (data.from === data.to) return

      let slotData = this.$store.getters.getSlot(data.from)
      slotData['slot'] = data.to

      await this.removeSlotData(data.to)
      await this.storeSlotData(slotData)
      await this.removeSlotData(data.from)
      this.update()
      this.$emit('resetConfig')
      this.$emit('validate', this.checkIfFull())
    },
    checkIfFull() {
      let full = this.$store.state['slot_1'] !== undefined
        && this.$store.state['slot_2'] !== undefined
        && this.$store.state['slot_3'] !== undefined

      return full
    },
    toggleInfoOverlay() {
      this.overlayVisible = !this.overlayVisible
    },
    async removeAll() {
      this.overlayVisible = false
      await this.removeSlotData(1)
      await this.removeSlotData(2)
      await this.removeSlotData(3)
      await this.update()
      await this.$emit('validate', this.checkIfFull())
    },
    update() {
      this.$refs.swiper.$forceUpdate()
      if (isSafari()) this.$refs.swiper.forceRepaint()
      for (let n = 1; n <= 3; n++) {
        let slot = document.getElementById('slot_'+n)
        let data = this.$store.getters.getSlot(n)
        if (data) {
          let thumbnail = this.$store.getters.getImageData(data.code, data.index)

          slot.setAttribute('href', thumbnail)
        } else {
          slot.setAttribute('href', '')
        }
      }
    },
    fixSafariSVGEmbbedBug() {
      function fixSizing() {
        let height = document.getElementById('configurator_svg').clientHeight
        if (height === undefined) {
          setTimeout(fixSizing, 1000)
        } else {
          document.getElementsByClassName('bigstrip-embedded-swiper')[0].style.width = '24%'
          document.getElementsByClassName('bigstrip-embedded-swiper')[0].style.height = height+'px'
          document.getElementsByClassName('bigstrip-embedded-swiper-container')[0].style.width = '24%'
          document.getElementsByClassName('bigstrip-embedded-swiper-container')[0].style.height = height+'px'
        }
      }
      fixSizing()
    },
    setupSlotDragging() {
      let context = this
      let startY = 0
      let dragging = false
      let dragIndex = -1

      $(window).scroll(function () {
        startY = $(this).scrollTop()
      })

      let dragStartListener = function(eve, n) {
        if (eve.changedTouches != undefined && eve.changedTouches.length == 0) {
          return
        }

        disableScroll()

        let data = context.$store.getters.getSlot(n)

        if (data) {
          if (isMobile() || !dragging) {
            let x = isMobile() ? eve.changedTouches[0].clientX : eve.clientX
            let y = isMobile() ? eve.changedTouches[0].clientY : eve.clientY
            let dragContainer = document.getElementsByClassName('bigstrip-drag-and-drop-container')[0]
            dragContainer.style.display = 'block'
            dragContainer.style.top = (y+startY-dragContainer.clientHeight/2)+'px'
            dragContainer.style.left = (x-dragContainer.clientWidth/2)+'px'
            dragContainer.firstChild.src = context.$store.getters.getImageData(data.code, data.index)
            dragging = true
            dragIndex = n
          }
        }

        eve.preventDefault()
      }

      let dragMoveListener = function(eve) {

        if (isMobile() || dragging) {
          if (isMobile() && (!eve.changedTouches || !eve.changedTouches[0])) return;
          let x = isMobile() ? eve.changedTouches[0].clientX : eve.clientX
          let y = isMobile() ? eve.changedTouches[0].clientY : eve.clientY
          let dragContainer = document.getElementsByClassName('bigstrip-drag-and-drop-container')[0]
          dragContainer.style.top = (y+startY-dragContainer.clientHeight/2)+'px'
          dragContainer.style.left = (x-dragContainer.clientWidth/2)+'px'
        }
        eve.preventDefault()
      }

      let dragEndListener = function(eve, n) {
        enableScroll()

        if (isMobile() && eve.changedTouches != undefined && eve.changedTouches.length == 0) {
          return
        }

        if (isMobile() || dragging) {
          if (isMobile() && (!eve.changedTouches || !eve.changedTouches[0])) return;
          let x = isMobile() ? eve.changedTouches[0].clientX : eve.clientX
          let y = isMobile() ? eve.changedTouches[0].clientY : eve.clientY
          let dragContainer = document.getElementsByClassName('bigstrip-drag-and-drop-container')[0]
          dragContainer.style.display = 'none'

          let dragContainers = document.getElementById('image_slots').getElementsByTagName('image')

          if (!isMobile()) n = dragIndex
          dragIndex = -1

          if (isInsideLeftsideDragzone(x, y)) {
            let data = context.$store.getters.getSlot(n)
            if (data) {
              context.removeSlotData(n)
              context.update()
              context.$emit('validate', context.checkIfFull())
            }
          }

          for (let k = 0; k < dragContainers.length; k++) {
            let container = dragContainers[k]
            if (isInside(x, y, container)) {
              let data = context.$store.getters.getSlot(n)
              // exclude empty slot and same slot
              if (data && k+1 !== n) {
                context.onMoveToSlot({
                  from: n,
                  to: (k+1)
                })
              }
            }
          }
          dragging = false
        }
        eve.preventDefault()
      }

      for (let n = 1; n <= 3; n++) {
        let slot = document.getElementById('slot_'+n)

        if (isMobile()) {
          slot.addEventListener('touchstart', (e) => dragStartListener(e, n))
          slot.addEventListener('touchmove', (e) => dragMoveListener(e))
          slot.addEventListener('touchend', (e) => dragEndListener(e, n))
        } else {

          let body = document.getElementsByTagName('body')[0]

          slot.addEventListener('mousedown', (e) => dragStartListener(e, n))
          body.addEventListener('mousemove', (e) => dragMoveListener(e))
          body.addEventListener('mouseup', (e) => dragEndListener(e, n))
        }

      }
    },
  },
  async mounted() {

    await this.$store.dispatch('resetFullIndicator')

    if (isSafari()) this.fixSafariSVGEmbbedBug()

    await this.$emit('setupButton', {
      text: 'WEITER',
      visible: true
    })

    this.setupSlotDragging()
    await this.update()
    await this.$emit('validate', this.checkIfFull())
  }
}

function isInsideLeftsideDragzone(x, y) {
  if (isSafari()) {
    let rect = document.getElementsByClassName('bigstrip-embedded-swiper')[0].getBoundingClientRect()
    return x > rect.left &&
        x < rect.right &&
        y > rect.top &&
        y < rect.bottom
  } else {
    let rect = document.getElementById('BigstripDragAndDrop').getBoundingClientRect()
    return x > rect.left &&
        x < rect.right &&
        y > rect.top &&
        y < rect.bottom
  }
}

function isInside(x,y , slot) {
  let rect = slot.getBoundingClientRect()
  if ( x > rect.left &&
      x < rect.right &&
      y > rect.top &&
      y < rect.bottom) {
    return true
  } else {
    return false
  }
}

let supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; return 1}
  }));
} catch(e) {console.error(e)}

let wheelOpt = supportsPassive ? { passive: false } : false;
let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// call this to Disable
function disableScroll() {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  window.removeEventListener('touchmove', preventDefault, wheelOpt);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

function isSafari() {
  return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1;
}

window.addEventListener('touchforcechange', function(event) {
  let force = event.changedTouches[0].force;
  let id = event.changedTouches[0].target.id;

  if ($('#' + id).hasClass('class') && force > 0.1) {
    event.preventDefault();
  }
});

function isMobile() {
  return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)
}


</script>

<style scoped>
  .st0{fill:#FFFFFF;}

  .st5{fill:#CC0099;}
  .st1{opacity:0.95;fill:#CC0099;enable-background:new    ;}
  .st2{fill:#FFFFFF;}
  .st3{font: 'normal normal bold 57px/20px Neusa Next Std';}
  .st4{font-size:57px;}

  .bigstrip-configurator-grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
  }

  .bigstrip-configurator-grid-main { grid-area: 1 / 1 / 2 / 2; }
  .bigstrip-configurator-grid-info-overlay { grid-area: 1 / 1 / 2 / 2; }
  .bigstrip-configurator-grid-help { grid-area: 1 / 1 / 2 / 2; }
  .bigstrip-configurator-grid-reset { grid-area: 1 / 1 / 2 / 2; }

  .bigstrip-configurator-grid-main {
    background-image: url("../../assets/orderpage/configurator/background.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .bigstrip-configurator-grid-reset {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .bigstrip-configurator-grid-reset>img {
    width: 8%;
    padding: 20px;
  }

  .bigstrip-configurator-grid-help {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .bigstrip-configurator-grid-help>span {
    padding: 8px 4px 8px 8px;
    color: var(--bigstrip-color);
    text-decoration: underline;
  }

  .bigstrip-configurator-grid-help>img {
    width: 3%;
    padding: 8px 8px 8px 4px;
  }

  .bigstrip-configurator-grid-info-overlay {
    background: #00000066;
    z-index: 99999999;
  }

  .bigstrip-embedded-swiper-container {
    width: 100%;
    height: 100%;
    position: fixed;
  }

  .bigstrip-drag-and-drop-container {
    position: absolute;
    height: auto !important;
    top: 0px;
    left: 0px;
    color: white;
    font-size: 1.5rem;
    display: none;
    overflow: hidden;
    z-index: 100;
  }

  .bigstrip-drag-and-drop-container>img {
    width: 80px;
    border-radius: 5px;
    border: 3px dashed #ccc;
  }

  .bigstrip-drag-and-drop-container>img.dance-up {
    display: none;
  }

  .bigstrip-configurator-grid-help, .bigstrip-configurator-grid-reset {
    pointer-events: none;
  }

  .bigstrip-configurator-remove-all-button, .bigstrip-configurator-grid-help * {
    pointer-events: all;
  }

</style>
