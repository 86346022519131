<template>
  <div class="bigstrip-provider-previewer">
    <div>Zahlungsart</div>
    <div>{{ provider }}</div>
  </div>
</template>

<script>
export default {
  name: "BigstripProviderPreviewer",
  props: [
      'provider'
  ]
}
</script>

<style scoped>

.bigstrip-Provider-previewer {
  margin-bottom: 20px;
}

.bigstrip-provider-previewer>div:first-child {
  font-family: "Neusa Next Std Bold";
}

.bigstrip-provider-previewer>div {
  font-size: 1.0rem;
  text-align: left;
}

</style>