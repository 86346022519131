<template>
  <div v-if="visible" class="BasicButtonWrapper" :style="{margin: computedStyle}">
    <button class="BasicButtonButton"
      v-on:click="handleClick"
      v-bind:class="{BasicButtonButtonSlim: slim}"
      :style="{background: bgColor}"
    ><slot></slot></button>
  </div>
</template>

<script>
export default {
  name: "BasicButton",
  props: ['step', 'color'],
  data() {
    return {
      enabled: true,
      visible: true,
      text: '',
      margin: 0,
      slim: false
    }
  },
  computed: {
    bgColor() {
      if (this.enabled) {
        return this.color ? this.color : "var(--bigstrip-color)"
      } else {
        return "#888888"
      }
    },
    computedStyle() {
      return "margin: 15px "+this.margin+"px;"
    }
  },
  methods: {
    handleClick() {
      if (this.enabled) {
        this.$emit('buttonClicked')
      }
    },
    setEnabled(enable) {
      this.enabled = enable
      this.$forceUpdate()
    },
    setVisible(visible) {
      this.visible = visible
    },
    setText(text) {
      this.text = text
    },
    setSlim(slim) {
      this.slim = slim
    }
  }
}
</script>

<style scoped>

  .BasicButtonButtonSlim {
    height: 40px !important;
    font: normal normal bold 12px/12px Neusa Next Std;
    width: 100% !important;
  }

  .BasicButtonButtonDisabled {
    background-color: #ccc !important;
  }

  .BasicButtonWrapper {
    max-width: var(--bigstrip-size);
    margin: 0 auto;
    margin-top: 40px;
  }

  .BasicButtonButton {
    text-align: center;
    font: normal normal bold 16px/12px Neusa Next Std;
    letter-spacing: 0.4px;
    color: var(--bigstrip-light-color);
    opacity: 1;
    background: var(--bigstrip-color) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    height: 48px;
    width: 90%;
    outline: none;
    border: none;
  }

  .BasicButtonButton:hover {
    cursor: pointer;
  }
</style>
