<template>
    <div class="BigstripFAQ">
        <div class="BigstripFAQHeader">Häufig gestellte Fragen</div>
        <bigstrip-f-a-q-entry
                question="Wo wird mein B!G STRIP produziert?"
                answer="Die B!G STRIPS werden bei uns im Zentrallager In Oberösterreich gedruckt und von hier versendet."
        />
        <bigstrip-f-a-q-entry
                question="Ist mein Fotocode für andere Zugriffe sicher?"
                answer="Die B!G STRIPS werden bei uns im Zentrallager In Oberösterreich gedruckt und von hier versendet."
        />
        <bigstrip-f-a-q-entry
                question="Wie lange dauert der Versand?"
                answer="Die B!G STRIPS werden bei uns im Zentrallager In Oberösterreich gedruckt und von hier versendet."
        />
        <bigstrip-f-a-q-entry
                question="Wieso habe ich keine Fotocode?"
                answer="Die B!G STRIPS werden bei uns im Zentrallager In Oberösterreich gedruckt und von hier versendet."
        />
        <bigstrip-f-a-q-entry
                question="Kann man bei euch auch Fotokabinen kaufen?"
                answer="Die B!G STRIPS werden bei uns im Zentrallager In Oberösterreich gedruckt und von hier versendet."
        />
        <bigstrip-f-a-q-entry
                question="Kann man in der Kabine mit Karte zahlen?"
                answer="Die B!G STRIPS werden bei uns im Zentrallager In Oberösterreich gedruckt und von hier versendet."
        />
        <bigstrip-f-a-q-entry
                question="Kann man auch kleine Fotos bestellen?"
                answer="Die B!G STRIPS werden bei uns im Zentrallager In Oberösterreich gedruckt und von hier versendet."
        />
        <bigstrip-f-a-q-entry
                question="Ich bin eine häufig gestellte Frage?"
                answer="Die B!G STRIPS werden bei uns im Zentrallager In Oberösterreich gedruckt und von hier versendet."
        />
        <bigstrip-f-a-q-entry
                question="Wie kann man euch kontaktieren?"
                answer="Die B!G STRIPS werden bei uns im Zentrallager In Oberösterreich gedruckt und von hier versendet."
                no-line=true
        />
    </div>
</template>

<script>

    import BigstripFAQEntry from "@/components/mainpage/BigstripFAQEntry";

    export default {
        name: "BigstripFAQ",
        components: {
            BigstripFAQEntry
        },
        mounted() {
            /*this.$http.get('https://siro.fforganizer.at/images/luminaires/type/mono_logo.png', {
                responseType: 'arraybuffer'
            })
                .then(response => {
                    let base64 = 'data:image/png;base64,'+Buffer.from(response.data, 'binary').toString('base64')
                    console.log(base64)
                })*/
        }
    }
</script>

<style scoped>

    .BigstripFAQ {
        background: #B0A682;
        color: var(--bigstrip-light-color);
        padding-bottom: 50px;
    }

    .BigstripFAQHeader {
        font: normal normal normal 32px/8px Neusa Next Std;
        padding-top: 80px;
        padding-bottom: 50px;
    }

</style>