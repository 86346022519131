<template>
    <div class="BigstripInfoRegion">
        <bigstrip-info-container nr="1" em="6">
            Mach ein Foto in einer unserer B!G STRIPS Fotoautomaten. <br/><a style="cursor: pointer">Wo finde ich die Fotoautomaten?</a>
        </bigstrip-info-container>
        <bigstrip-info-container nr="2" em="9">
            Gib deinen Fotocode auf unserer Bestellseite ein und konfiguriere dir deinen B!G STRIP.
        </bigstrip-info-container>
        <bigstrip-info-container nr="3" em="6">
            Erhalte deinen B!G STRIP zu dir nach Hause.
        </bigstrip-info-container>
    </div>
</template>

<script>

    import BigstripInfoContainer from "@/components/mainpage/BigstripInfoContainer";

    export default {
        name: "BigstripInfoRegion",
        components: {BigstripInfoContainer},
        comments: {
            BigstripInfoContainer
        }
    }
</script>

<style scoped>

  .BigstripInfoRegion {
    padding: 15px;
  }

</style>