<template>
  <div class="checkbox">
    <div class="checkbox-wrapper" v-on:click="toggleCheckbox">
      <div class="checkbox-checked" v-if="checked">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
          <g transform="translate(-20 -752)">
            <rect class="a" width="30" height="30" transform="translate(20 752)"/>
            <g transform="translate(-899 412)">
              <rect class="b" width="16" height="16" transform="translate(926 347)"/>
              <path class="c" d="M6.345,11.034,0,4.69,1.287,3.4,6.345,8.368,14.713,0,16,1.287Z"
                    transform="translate(926 349)"/>
            </g>
          </g>
        </svg>
      </div>
      <div class="checkbox-unchecked" v-if="!checked">
        <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 291.46 291.46">
          <rect class="cls-1" x="4" y="4" width="283.46" height="283.46"/>
        </svg>
      </div>
    </div>
    <label class="checkbox-name" :style='{"font-size": fontSize}'>
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  data() {
    return {
      checked: false,
      size: 1.0
    }
  },
  methods: {
    toggleCheckbox() {
      this.checked = !this.checked
      this.$emit('toggle', this.checked)
    },
    setChecked(checked) {
      this.checked = checked
    },
    setFontSize(size) {
      this.size = size
    }
  },
  computed: {
    fontSize() {
      return this.size+'rem'
    }
  }
}
</script>

<style scoped>
.checkbox {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
}

.checkbox-wrapper {
  width: 30px;
  height: 30px;
}

.checkbox-checked {
  width: 100%;
  height: 100%;
}

.checkbox-unchecked {
  width: 100%;
  height: 100%;
}

.checkbox-name {
  padding-left: 10px;
}

.a {
  fill: #c09;
}

.b {
  fill: none;
}

.c {
  fill: #fff;
}

.cls-1 {
  fill: #fff;
  stroke: #28231C;
  stroke-miterlimit: 10;
  stroke-width: 8px;
}

</style>