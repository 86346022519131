<template>
    <div class="BigstripImageRowContainer">
        <div>{{code}}</div>
        <div class="BigstripImageRow" ref="images">
            <div class="BigstripImageRowElem" v-for="(image, index) in images" :key="index" :style="{width: calcElemWidth, height: calcElemWidth}">
                <img :src="image.src">
            </div>
            <div class="BigstripImageRow" v-if="images.length === 0">
                <div class="BigstripImageRowPlaceholder" :style="{width: calcElemWidth, height: calcElemWidth}"><div>1</div></div>
                <div class="BigstripImageRowPlaceholder" :style="{width: calcElemWidth, height: calcElemWidth}"><div>2</div></div>
                <div class="BigstripImageRowPlaceholder" :style="{width: calcElemWidth, height: calcElemWidth}"><div>3</div></div>
            </div>
        </div>
        <img
                class="BigstripImageRowRemove"
                v-on:click="remove"
                v-if="images.length !== 0"
                src="../../../assets/remove.svg"
        />
    </div>
</template>

<script>
    export default {
        name: "BigstripImageRow",
        props: {
            code: {
                type: String,
                default: ''
            },
            images: {
                type: Array,
                default: () => [],
            }
        },
        computed: {
          calcElemWidth() {
            const style = getComputedStyle(document.body)
            let bigstrip_size = style.getPropertyValue('--bigstrip-size')
            bigstrip_size = parseInt(bigstrip_size.replace('px', ''))
            let conatiner_size = (window.innerWidth>bigstrip_size?bigstrip_size:window.innerWidth)
            let px = conatiner_size/3.5
            return px+'px'
          }
        },
        methods: {
            remove() {
                this.$emit('removeImageRow', this.code)
            }
        }
    }
</script>

<style scoped>

    .BigstripImageRowContainer {
        margin: 15px;
    }

    .BigstripImageRow {
        display: flex;
        width: 100%;
        justify-content: space-between;
        grid-auto-flow: column;
    }

    .BigstripImageRowElem img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .BigstripImageRowElem {
        background: var(--bigstrip-color);
        width: 30%;
    }

    .BigstripImageRowPlaceholder {
        width: 30vw;
        height: 30vw;
        background: #CCCCCC;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .BigstripImageRowPlaceholder div {
        font: normal normal bold 32px/12px Neusa Next Std;
        letter-spacing: 0.8px;
        color: var(--bigstrip-light-color);
    }

    .BigstripImageRowRemove {
        margin-top: -4.4vw;
    }

    .BigstripImageRowElem div {
        width: 100%;
        height: 100%;
    }

</style>