<template>
  <div class="BigstripTitle noselect">
    <img src="../../../public/common/logo.svg">
  </div>
</template>

<script>
export default {
  name: "BigstripTitle"
}
</script>

<style scoped>
  .BigstripTitle {
    margin: 0 auto;
    display: inline-flex;
    align-content: center;
    align-items: center;
    height: 100%;
  }

  .BigstripTitle img {
    vertical-align: center;
  }
</style>
