<template>
  <div class="bigstrip-address-previewer">
    <div>{{ address.title }}</div>
    <div>{{ address.firstname }} {{ address.lastname }}</div>
    <div>{{ address.street }}</div>
    <div>{{ address.postal_code }} {{ address.city }}, {{ address.country }}</div>
    <!--div>{{ address.phone_nr }}</div-->
    <div>{{ address.email }}</div>
  </div>
</template>

<script>
export default {
  name: "BigstripAddressPreviewer",
  props: [
      'address'
  ]
}
</script>

<style scoped>

.bigstrip-address-previewer {
  margin-bottom: 15px;
}

.bigstrip-address-previewer>div:first-child {
  font-family: "Neusa Next Std Bold";
}

.bigstrip-address-previewer>div {
  font-size: 1.0rem;
  text-align: left;
}

</style>