<template>
  <div class="bigstrip-voucher-input-field">
    <label class="bigstrip-voucher-label">Rabattcode</label>
    <input class="bigstrip-voucher-input" type="text" v-model="voucher" />
  </div>
</template>

<script>
export default {
  name: "BigstripVoucherInputField",
  data() {
    return {
      voucher: ''
    }
  },
  methods: {
    getVoucher() {
      return this.voucher
    }
  }
}
</script>

<style scoped>

  .bigstrip-voucher-input-field {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .bigstrip-voucher-label {
    font-size: 0.65rem;
    color: var(--bigstrip-color);
  }

  .bigstrip-voucher-input {
    padding: 8px 5px;
    border: 1px solid var(--bigstrip-color);
    outline: none;
    color: var(--bigstrip-color);
    font-weight: bold;
    font-family: "Courier New";
    font-size: 1.2rem;
    text-transform: uppercase;
    box-sizing: border-box;
  }

</style>