<template>
  <div class="bigstrip-form-entry" ref="entry">
    <label><slot></slot></label>
    <input v-model="value"
        :type="type"
        v-on:focusin="focusIn"
        v-on:focusout="focusOut"/>
  </div>
</template>

<script>
export default {
  name: "BigstripFormEntry",
  data() {
    return {
      value: '',
      type: 'text',
      valid: false
    }
  },
  computed: {
    inputReady() {
      return this.value
    }
  },
  watch: {
    inputReady() {
      this.onChange()
    }
  },
  methods: {
    focusIn(eve) {
      let input = eve.target
      let label = input.parentElement.getElementsByTagName('label')[0]
      input.style.border = '2px solid var(--bigstrip-color)';
      label.style.color = 'var(--bigstrip-color)';
    },
    onChange() {
      switch (this.type) {
        case 'text':
        case 'number':
          this.valid = this.value !== ''
          break;
        case 'tel':
          this.valid = this.validatePhoneNumber(this.value)
          break;
        case 'email':
          this.valid = this.validateMail(this.value)
          break;
      }
      this.$emit('update')
    },
    focusOut(eve) {
      let input = eve.target
      let label = input.parentElement.getElementsByTagName('label')[0]
      input.style.border = '1px solid #28231C';
      label.style.color = '#28231C';
    },
    setType(type) {
      this.type = type
    },
    validatePhoneNumber(number) {
      if (!number) return false
      let regex = /^(\+43|0043|0|43)[0-9]*$/
      number = number.replace(/\//,'')
      number = number.replace(/%s/,'')
      this.invalid_phone = number.length > 8 && !regex.test(number)
      return number.length > 8 && regex.test(number)
    },
    validateMail(mail) {
      if (!mail) return false
      let regex = new RegExp("^(([a-zA-Z0-9]+\\.)*[a-zA-Z0-9]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$")
      this.invalid_mail = mail.length > 15 && !regex.test(mail)
      return regex.test(mail)
    },
    isValid() {
      return this.valid
    },
    getValue() {
      return this.value
    },
    setValue(value) {
      this.value = value
    }
  }
}
</script>

<style scoped>

.bigstrip-form-entry {
  width: 100%;
}

.bigstrip-form-entry label {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 0.65rem;
  color: #28231C;
}

.bigstrip-form-entry input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 48px;
  border: 1px solid #28231C;
  outline: none !important;
}

</style>