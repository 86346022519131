<template>
    <div class="BigstripInfoContainer">
        <div class="BigstripInfoContainerNr">{{nr}}.</div>
        <div class="BigstripInfoContainerImage">
            <img ref="image" :style="{width: imageWidth}"/>
        </div>
        <div class="BigstripInfoContainerText"><slot></slot></div>
    </div>
</template>

<script>
    export default {
        name: "BigstripInfoContainer",
        props: ['nr', 'em'],
        mounted() {
            this.$refs.image.src = "mainpage/info-region/"+this.nr+".svg"
        },
        computed: {
            imageWidth() {
                return this.em + "em"
            }
        }
    }
</script>

<style scoped>

    .BigstripInfoContainer {
        margin-top: 67px;
    }

    .BigstripInfoContainerNr {
        text-align: left;
        letter-spacing: 0px;
        color: #28231C;
        opacity: 1;
        font-size: 1.8rem;
        position: absolute;
    }

    .BigstripInfoContainerImage img {
    }

</style>