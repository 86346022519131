<template>
  <div class="impressum-container">
    <bigstrip-header />
    <div class="impressum">
      <h1>Impressum</h1>


      <h2>§1.	Gestaltung der Bedingungen</h2>
      <p>
        Lieferungen, Leistungen und Angebote des Blumenfachgeschäfts erfolgen aus-schließlich aufgrund der einheitlich geltenden „Allgemeinen Geschäftsbedingungen der Blumenfachgeschäfte“.

        Von diesen AGBs abweichende oder diese ergänzende Vereinbarungen bedürfen der Schriftform. Sollten einzelne Bestimmungen dieser ‚Einheitlichen Geschäftsbedingungen unwirksam sein, so berührt dies die Verbindlichkeit der übrigen Bestimmun-gen und der unter ihrer Zugrundelegung geschlossenen Verträge nicht. Die unwirksame Bestimmung ist durch eine wirksame, die ihr dem Sinn und Zweck am nächsten kommt, zu ersetzen.
      </p>

      <h2>§2.	Vertragsabschluss</h2>
      <p>
        Schriftliche Angebote des Blumenfachgeschäfts können nur unverzüglich angenommen werden, es sei denn, dass eine längere Bindung vereinbart ist. Nebenabreden können nur schriftlich vereinbart werden.
      </p>

      <h2>§3.	Preise</h2>
      <p>
        Die Preise des Blumenfachgeschäfts verstehen sich einschließlich der gültigen Umsatzsteuer.
        Zusatzleistungen, die über den eigentlichen Pflanzen- und Blumenverkauf hinausgehen, sind gesondert zu vergüten, also etwa Anlieferungen, Versand, Extrabeiwerk, Sonderverpackungen, Karten, Änderungen von Gebinden, Materialien, Arrangieren an drittem Ort, usw.
      </p>

      <h2>§4.	Fälligkeit</h2>
      <p>
        Die Lieferungen des Blumenfachgeschäfts sind sofort in bar zu vergüten, sofern nicht ausnahmsweise ein Zahlungsziel vereinbart wird. Schecks oder Wechsel werden nur zahlungshalber angenommen.
      </p>

      <p>Herausgegeben von der Bundesinnung der Gärtner und Floristen im März 2003.</p>
    </div>
  </div>
</template>

<script>

import BigstripHeader from "@/components/common/BigstripHeader";

export default {
  name: "BigstripDatenschutzPage",
  components: {
    BigstripHeader
  }
}
</script>

<style scoped>
.impressum-container {
  width: 100%;
  margin: 0 auto;
}

.impressum {
  text-align: justify;
  width: 60%;
  margin: 0 auto;
}

@media only screen and (max-width: 1200px) {
  .impressum {
    width: 100%;
  }

  .impressum h1 {
    font-size: 1.2rem;
    padding-top: 30px !important;
    padding-bottom: 20px !important;
  }

  .impressum h2 {
    font-size: 0.9rem;
    padding-left: 15px;
  }

  .impressum p {
    font-size: 0.7rem;
    padding: 15px;
    margin-bottom: 10px !important;
  }
}

.impressum h1 {
  padding-top: 100px;
  text-align: center;
  line-height: 48px;
  padding-bottom: 80px;
}

.impressum p {
  margin-bottom: 50px;
}

</style>