<template>
  <div class="BigstripOrderProgressItem" v-on:click="click">
    <div v-bind:style="displayColor"></div>
  </div>
</template>

<script>
export default {
  name: "BigstripOrderProgressItem",
  props: {
    "active": {type: Boolean, default: false},
    "last": {type: Boolean, default: false},
    "id": {type: Number}
  },
  methods: {
    click() {
      this.$emit('switchToStep', parseInt(this.id))
    }
  },
  computed: {
    displayColor: function () {
      return {
        'background-color': this.active ? 'var(--bigstrip-color)' : '#cccccc'
      }
    }
  }
}
</script>

<style scoped>
  .BigstripOrderProgressItem {
    display: inline-block;
    height: 100%;
  }

  .BigstripOrderProgressItem div {
    display: inline-block;
    width: 100%;
    height: 5px;
    transition: background-color 0.3s ease-in-out;
  }
</style>
