<template>
    <div id="BigstripMenu" class="noselect right">
        <div id="BigstripMenuButton" v-on:click="openMenu">
            <span />
            <span />
            <span />
        </div>
        <div id="BigstripMenuDropdown" v-if="menuVisible">
            <div id="BigstripMenuDropdownTitleWrapper">
                <bigstrip-title />
            </div>
            <div id="BigstripMenuDropdownLinks">
                <span v-on:click="menuClicked('start')">START</span>
                <span v-on:click="menuClicked('howto')">WIE FUNKIONIERT B!G STRIPS?</span>
                <span v-on:click="menuClicked('locations')">STANDORTE</span>
                <span v-on:click="menuClicked('faq')">FAQ</span>
                <span v-on:click="menuClicked('contact')">KONTAKT</span>
                <span v-on:click="menuClicked('delivery')">VERSANDINFORMATIONEN</span>
            </div>
        </div>
    </div>
</template>

<script>

    import BigstripTitle from "@/components/common/BigstripTitle";

    const MENU_BUTTON_SIZE = 55

    export default {
        name: "BigstripMenu",
        components: {
            BigstripTitle
        },
        data() {
          return {
              menuVisible: false
          }
        },
        methods: {
            menuClicked(redirect) {
              window.location.href = '#' + redirect;
              setTimeout(function () {
                    location.reload();
                });
            },
            openMenu() {
                this.menuVisible = !this.menuVisible
            }
        },
        mounted() {
          let context= this

          function catchMenuClick(eve) {
            if (!eve.changedTouches || !eve.changedTouches[0]) return
            let x = isMobile() ? eve.changedTouches[0].clientX : eve.clientX
            let y = isMobile() ? eve.changedTouches[0].clientY : eve.clientY

            if (x <= MENU_BUTTON_SIZE && y <= MENU_BUTTON_SIZE) {
              context.openMenu()
            }
          }

          if (isMobile()) {
            document.getElementsByTagName('body')[0].addEventListener('touchend', catchMenuClick)
          } else {
            document.getElementsByTagName('body')[0].addEventListener('mouseup', catchMenuClick)
          }
        }
    }

    function isMobile() {
      return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)
    }

</script>

<style scoped>

    #BigstripMenu {
        width: 100%;
        position: fixed;
        top: 0px;
        left: 0px;
        opacity: 0.9;
        z-index: 10;
    }

    #BigstripMenuButton {
        width: 55px;
        height: 55px;
        background: var(--bigstrip-color);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        margin-left: calc(100% - 55px);
        z-index: 110;
    }

    #BigstripMenuButton span {
        display: block;
        height: 2px;
        width: 17px;
        margin-top: 2px;
        border-radius: 2px;
        margin-bottom: 2px;
        background: #eeeeee;
        z-index: 110;
    }

    #BigstripMenuDropdown {
        top: -55px;
        height: fit-content;
        padding-bottom: 10px;
        background: var(--bigstrip-color);
        transition: opacity 0.2s ease-in-out;
    }

    #BigstripMenuDropdownTitleWrapper {
        height: 55px;
    }

    #BigstripMenuDropdownLinks {

    }

    #BigstripMenuDropdownLinks span {
        font: normal normal normal
        16px/20px Neusa Next Std;
        display: block;
        color: white;
        text-decoration: none;
        text-align: center;
        margin: 24px 0px;

    }

</style>