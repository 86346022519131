<template>
  <div class="bigstrip-checkout">
    <div class="bigstrip-step-headline" style="text-transform: capitalize;">Bestätigen</div>
    <div class="bigstrip-checkout-grid-container" :style='{"grid-template-rows": getGridTemplateRows}'>
      <div class="checkout_deliver_address" v-if="differentAddress">
        <bigstrip-address-previewer :address="deliverAddress"/>
      </div>
      <div class="checkout_change_deliver_address" v-if="differentAddress">
        <basic-button ref="deliver_address_button" color="#B0A682" @buttonClicked="changeAddress">ändern</basic-button>
      </div>
      <div class="checkout_billing_address">
        <bigstrip-address-previewer :address="billingAddress"/>
      </div>
      <div class="checkout_change_billing_address">
        <basic-button ref="billing_address_button" color="#B0A682" @buttonClicked="changeAddress">ändern</basic-button>
      </div>
      <div class="checkout_provider">
        <bigstrip-provider-previewer :provider="provider"/>
      </div>
      <div class="checkout_change_provider">
        <basic-button ref="provider_button" color="#B0A682" @buttonClicked="changeProvider">ändern</basic-button>
      </div>
      <div class="checkout_voucher">
        <bigstrip-voucher-input-field ref="voucher_input"/>
      </div>
      <div class="checkout_voucher_button">
        <basic-button ref="voucher_button" @buttonClicked="calcPrice">einlösen</basic-button>
      </div>
      <div class="checkout_order_positions">
        <div>Bestellung</div>
        <div>{{ getProductName }}</div>
        <div>{{ getMwstRate }}% MwSt.</div>
        <div>Versand</div>
        <div v-if="discountVisible">Rabatt</div>
        <div>Gesamt</div>
      </div>
      <div class="checkout_order_prices">
        <div>€</div>
        <div>{{ getNettoPrice }}</div>
        <div>{{ getMwst }}</div>
        <div>{{ getShipment }}</div>
        <div v-if="discountVisible">{{ getDiscount }}</div>
        <div>{{ getTotalPrice }}</div>
      </div>
    </div>
    <label v-if="this.$store.getters.getProvider === 'MasterCard' || this.$store.getters.getProvider === 'VISA'">
      <div class="checkout_order_form_label">Zahlungsdetails</div>
      <bigstrip-payment-form
          ref="form"
          @formCompleted="cardFormCompleted">

      </bigstrip-payment-form>
    </label>
    <div class="bigstrip-checkout-agb-check">
      <checkbox
          ref="agb_checkbox"
          @toggle="agbToggled"
      >
        Ich akzeptiere die <a>AGBs</a> und <a>Datenschutzbestimmung</a>
      </checkbox>
    </div>
    <div class="bigstrip-checkout-button-container"
         :style='{"filter": paypalButtonValidationFilter}'
      >
      <bigstrip-loading v-if="paying"/>
      <basic-button
          v-if="!paying && this.$store.getters.getProvider !== 'PayPal'"
          ref="payButton"
          @buttonClicked="payButtonClicked"
      >
        BEZAHLEN
      </basic-button>
    </div>
  </div>
</template>

<script>

import BasicButton from "@/components/common/BasicButton";
import Checkbox from "@/components/common/Checkbox";
import BigstripAddressPreviewer from "@/components/orderpage/checkout/BigstripAddressPreviewer";
import BigstripProviderPreviewer from "@/components/orderpage/checkout/BigstripProviderPreviewer";
import BigstripVoucherInputField from "@/components/orderpage/checkout/BigstripVoucherInputField";
import BigstripPaymentForm from "@/components/orderpage/checkout/BigstripPaymentForm";
import BigstripLoading from "@/components/common/BigstripLoading";

export default {
  name: "BigstripCheckout",
  components: {
    BigstripLoading,
    BigstripPaymentForm,
    BasicButton,
    Checkbox,
    BigstripAddressPreviewer,
    BigstripProviderPreviewer,
    BigstripVoucherInputField
  },
  data() {
    return {
      price: {},
      klarna_session: {},
      paying: false,
      agb: false,
      form: false,
      paypal_button: undefined
    }
  },
  computed: {
    deliverAddress() {
      let address = this.$store.getters.getDeliverAddress
      address['title'] = 'Lieferadresse'
      return address
    },
    billingAddress() {
      let address = this.$store.getters.getBillingAddress
      address['title'] = 'Rechnungsadresse'
      return address
    },
    provider() {
      return this.$store.getters.getProvider
    },
    differentAddress() {
      return this.$store.getters.hasDeliverAddress
    },
    getGridTemplateRows() {
      if (!this.$store.getters.hasDeliverAddress) {
        return '0fr 1fr 0.4fr 0.4fr 0.4fr'
      } else {
        return '1fr 1fr 0.4fr 0.4fr 0.4fr'
      }
    },
    getProductName() {
      let size = this.$store.getters.getSize
      let amount = this.$store.getters.getAmount
      return amount+' Stück B!GSTRIP '+this.$store.getters.getProducts[size]['dim']
    },
    getMwstRate() {
      return this.price !== {} ? this.prettyPrint(this.price['vat']*100) : '0.00'
    },
    getMwst() {
      return this.price !== {} ? this.prettyPrint(this.price['tax']) : '0.00'
    },
    getNettoPrice() {
      return this.price !== {} ? this.prettyPrint(this.price['netto']) : '0.00'
    },
    getShipment() {
      return this.price !== {} ? this.prettyPrint(this.price['shipment_fee']) : '0.00'
    },
    getDiscount() {
      return this.price !== {} ? '-'+this.prettyPrint(this.price['discount']) : '0.00'
    },
    discountVisible() {
      return this.price !== {} ? this.price['discount'] !== 0 : false
    },
    getTotalPrice() {
      return this.price !== {} ? this.prettyPrint(this.price['total']) : '0.00'
    },
    paypalButtonValidationFilter() {
      let paymentMethod = this.$store.getters.getProvider.toLowerCase();
      if (paymentMethod !== 'paypal') return 'grayscale(0)'
      else return this.agb ? 'grayscale(0)' : 'grayscale(1.0)'
    }
  },
  methods: {
    prettyPrint(number) {
      if (parseInt(number*100) === 0) return '-'
      let formatted = (parseInt(Math.round(number*100))/100.0)+''
      let length = formatted.length
      if (formatted[length - 2] === '.') {
        formatted = formatted + '0'
      } else if (formatted[length - 3] !== '.' && formatted[1] !== '.') {
        formatted = formatted + '.00'
      }
      return formatted.replace(/\./g, ',')
    },
    changeAddress() {
      this.$emit('switchToStep', 'form')
    },
    changeProvider() {
      this.$emit('switchToStep', 'provider')
    },
    cardFormCompleted(complete) {
      this.form = complete
      this.updatePayButton()
    },
    agbToggled(toggle) {
      this.agb = toggle
      this.updatePayButton()
    },
    updatePayButton() {
      let enabled = false
      let paymentMethod = this.$store.getters.getProvider.toLowerCase();

      if (paymentMethod === 'klarna' && this.agb && this.klarna_session) enabled = true
      else if (paymentMethod === 'visa' && this.form && this.agb) enabled = true
      else if (paymentMethod === 'mastercard' && this.form && this.agb) enabled = true

      if (paymentMethod === 'paypal') {
        if (this.agb) {
          this.paypal_button.enable()
        } else {
          this.paypal_button.disable()
        }
      } else {
        this.$refs.payButton.setEnabled(enabled && !this.paying);
      }
    },
    async waitTillRefAvailable(context, name) {
      return new Promise((resolve, reject) => {
        let timer = undefined, timeout = undefined
        timeout = setTimeout(() => {
          clearInterval(timer)
          reject()
        }, 500)
        timer = setInterval(function () {
          if (context.$refs[name] !== undefined) {
            clearTimeout(timeout)
            clearInterval(timer)
            resolve()
          }
        }, 50)
      })
    },
    async calcPrice() {
      let context = this
      let paymentMethod = this.$store.getters.getProvider.toLowerCase();

      if (paymentMethod === 'klarna') {
        await this.initKlarnaSession()
      }

      return new Promise((resolve) => {
        context.$http.post(process.env.VUE_APP_BACKEND_ENDPOINT+'/api/price',
            this.getCustomerData(),
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }).then(response => {
          context.price = response['data']
          resolve()
        })
      })
    },
    getConfiguration() {
      return {
        size: this.$store.getters.getSize,
        amount: this.$store.getters.getAmount,
        slot_1: JSON.parse(JSON.stringify(this.$store.getters.getSlot1)),
        slot_2: JSON.parse(JSON.stringify(this.$store.getters.getSlot2)),
        slot_3: JSON.parse(JSON.stringify(this.$store.getters.getSlot3)),
        voucher: this.$refs.voucher_input.getVoucher()
      }
    },
    getCustomerData() {
      function fixAddress(address) {
        address['mail'] = address['email']
        address['zipcode'] = address['postal_code']
        return address
      }
      return {
        billing_address: fixAddress(this.$store.getters.getBillingAddress),
        deliver_address: fixAddress(this.$store.getters.getDeliverAddress),
        configuration: this.getConfiguration(),
      }
    },
    initPayPalButton() {

      let context = this
      // eslint-disable-next-line no-undef
      paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'blue',
          layout: 'horizontal',
          label: 'pay',
          tagline: false,
          height: 40
        },
        onInit: function(data, actions) {
          actions.disable();
          context.paypal_button = actions
        },
        createOrder: function(data, actions) {
          return actions.order.create({
            purchase_units: [
              {
                "amount": {
                  "currency_code": "EUR",
                  "value": context.price['total']
                }
              }
            ],
            "items": {
              "item": {
                "name": context.price['name'],
                "quantity": '1'
              }
            },
            "payer":{
              "name":{
                "given_name": context.$store.getters.getBillingAddress.lastname,
                "surname": context.$store.getters.getBillingAddress.firstname
              },
              "email_address": context.$store.getters.getBillingAddress.email,
              "address":{
                "country_code":"AT"
              }
            },
          })
        },
        onApprove: function(data, actions) {
          return actions.order.capture().then(function(orderData) {
            context.finishPaypalPayment(orderData)
          })
        },
        onError: function() {
          context.moveToFailurePage()
        }
      }).render('.bigstrip-checkout-button-container');
    },
    removePaypalButton() {
      document.getElementsByClassName('paypal-buttons')[0].remove()
    },
    initSession(paymentMethod) {
      if (paymentMethod === 'PayPal') {
        this.initPayPalButton()
      } else if (paymentMethod === 'Klarna') {
        this.initKlarnaSession()
      } else {
        this.initCardSession(paymentMethod)
      }
    },
    initKlarnaSession() {
      let context = this
      this.$http.post(process.env.VUE_APP_BACKEND_ENDPOINT+'/api/checkout/klarna/setup',
          this.getCustomerData(),
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(response => {
        context.klarna_session = response['data']
        context.updatePayButton()
      })
    },
    initCardSession(paymentMethod) {
      let context = this

      console.log('CARD SETUP REQ')
      console.log(this.getCustomerData())

      this.$http.post(process.env.VUE_APP_BACKEND_ENDPOINT+'/api/checkout/'+paymentMethod.toLowerCase()+'/setup',
          this.getCustomerData(),
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(response => {
            context['card_session'] = response['data']
            console.log('CARD_SESSION')
            console.log(response['data'])
          })
    },
    async payButtonClicked() {
      let customerData = this.getCustomerData();
      let paymentMethod = this.$store.getters.getProvider;

      if (paymentMethod === 'Klarna') {
        this.paying = true;
        customerData['order_data'] = this.klarna_session;
        await this.createKlarnaOrder(customerData);
        window.location.href = this.klarna_session['klarna']['pay_now_redirect_url']
      } else {
        this.paying = true
        let paymentIntent = await this.$refs.form.pay(this.card_session['client_secret']);
        this.finishCardPayment(paymentIntent, paymentMethod)
      }
    },
    finishPaypalPayment(orderData) {
      this.removePaypalButton()
      this.paying = true
      let customerData = this.getCustomerData()
      customerData['order_data'] = orderData
      this.$http.post(process.env.VUE_APP_BACKEND_ENDPOINT+'/api/checkout/paypal',
          customerData,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(async response => {
            let orderNr = response['data']['order']
            await this.waitForApprove(orderNr)
          }).catch(err => {
            console.error(err)
          })
    },
    finishCardPayment(paymentIntent, paymentMethod) {
      let customerData = this.getCustomerData()
      customerData['order_data'] = paymentIntent

      console.log('CARD FINISH REQ')
      console.log(customerData)

      this.$http.post(process.env.VUE_APP_BACKEND_ENDPOINT+'/api/checkout/'+paymentMethod.toLowerCase(),
        customerData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
      }).then(async response => {
        let orderNr = response['data']['order']

        console.log('CARD FINISH')
        console.log(response['data'])

        await this.waitForApprove(orderNr)
      }).catch(err => {
        console.error(err)
      })
    },
    async sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async waitForApprove(orderNr) {
      let status = 'Payment-Pending'
      // check every second if payment was approved
      do {
        await this.sleep(1000)
        let response = await this.$http.get(process.env.VUE_APP_BACKEND_ENDPOINT+'/api/checkout/status/'+orderNr)
        let payload = response['data']
        if (payload && payload['status']) {
          status = payload['status']
        }
      } while (status === 'Payment-Pending');

      if (status !== 'Canceled' && status !== 'Declined') {
        this.moveToSuccessPage()
      } else {
        this.moveToFailurePage()
      }
    },
    async createKlarnaOrder(order) {
      let context = this
      return new Promise((resolve) => {
        context.$http.post(process.env.VUE_APP_BACKEND_ENDPOINT+'/api/checkout/klarna', order)
            .then(response => {
              let orderNr = response['data']['order']
              resolve(orderNr)
            }).catch(err => {
              console.error(err)
            })
      })
    },
    getUrlParameters() {
      if (location.search === '') return []
      let parts = (location.search.substring(1)+'&').match(/(.*?)=(.*?)&/g)
      let data = {}
      parts.forEach(elem => {
        let tuple = elem.substr(0, elem.length - 1).split(/=/g)
        data[tuple[0]] = tuple[1]
      })
      return data
    },
    moveToSuccessPage() {
      this.$router.push({
        name: 'success'
      })
    },
    moveToFailurePage() {
      this.$router.push({
        name: 'failure'
      })
    }
  },
  async mounted() {

    if (this.$store.getters.getProvider !== 'PayPal') {
      this.$refs.payButton.setEnabled(false)
    }

    this.waitTillRefAvailable(this, 'deliver_address_button')
    .then(() => this.$refs.deliver_address_button.setSlim(true))
    .catch(() => console.log('Ignore timeout for deliver button, if not visible'))
    this.waitTillRefAvailable(this, 'billing_address_button')
        .then(() => this.$refs.billing_address_button.setSlim(true))
    this.waitTillRefAvailable(this, 'provider_button')
        .then(() => this.$refs.provider_button.setSlim(true))
    this.waitTillRefAvailable(this, 'voucher_button')
        .then(() => this.$refs.voucher_button.setSlim(true))
    this.waitTillRefAvailable(this, 'agb_checkbox')
        .then(() => this.$refs.agb_checkbox.setFontSize(0.70))

    let urlParams = this.getUrlParameters()

    this.$emit('setupButton', {
      text: '',
      visible: false
    })

    await this.calcPrice()

    // handle Klarna payment redirect
    if (Object.keys(urlParams).length !== 0) {
      let srcId = urlParams['source']
      this.paying = true
      history.replaceState({}, '', location.origin+'/#/order/checkout')
      let response = await this.$http.get(process.env.VUE_APP_BACKEND_ENDPOINT+'/api/checkout/klarna/'+srcId)
      let payload = response['data']
      if (payload && payload['order']) {
        let orderNr = payload['order']
        await this.waitForApprove(orderNr)
      }
    } else {
      await this.initSession(this.$store.getters.getProvider)

      let paymentMethod = this.$store.getters.getProvider

      // init visa/mastercard form
      if (paymentMethod !== 'PayPal' && paymentMethod !== 'Klarna') {
        this.$refs.form.init(this.price)
      }
    }
  }
}
</script>

<style scoped>

.checkout_voucher_button, .checkout_voucher {
  display: flex;
  align-items: flex-end;
}

.bigstrip-checkout-grid-container {
  display: grid;
  grid-template-columns: 0.75fr 0.25fr;
  gap: 15px 0px;
  margin: 15px;
}

.checkout_voucher_button>div {
  width: 100%;
}

.bigstrip-checkout-agb-check {
  margin: 35px 15px;
}

.checkout_order_positions>div:first-child, .checkout_order_positions>div:last-child,
.checkout_order_prices>div:first-child, .checkout_order_prices>div:last-child, .checkout_order_form_label {
  font-family: "Neusa Next Std Bold";
}

.checkout_order_positions>div {
  font-size: 1.0rem;
  text-align: left;
}

.checkout_order_prices>div {
  font-size: 1.0rem;
  text-align: right;
}

.checkout_change_deliver_address { grid-area: 1 / 2 / 2 / 3; }
.checkout_billing_address { grid-area: 2 / 1 / 3 / 2; }
.checkout_change_billing_address { grid-area: 2 / 2 / 3 / 3; }
.checkout_provider { grid-area: 3 / 1 / 4 / 2; }
.checkout_change_provider { grid-area: 3 / 2 / 4 / 3; }
.checkout_voucher { grid-area: 4 / 1 / 5 / 2; }
.checkout_voucher_button { grid-area: 4 / 2 / 5 / 3; }
.checkout_deliver_address { grid-area: 1 / 1 / 2 / 2; }
.checkout_order_positions { grid-area: 5 / 1 / 6 / 2; }
.checkout_order_prices { grid-area: 5 / 2 / 6 / 3; }

.bigstrip-checkout-button-container {
  width: 90%;
  margin: 20px auto;
}

.checkout_order_form_label {
  margin: 35px 15px 15px 15px;
  text-align: left;
}

.bigstrip-voucher-input-field {
  margin-right: 15px;
}

</style>