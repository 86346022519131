<template>
  <div id="BigstripSizeChooser">
    <div class="bigstrip-step-headline">How B!G?</div>
    <div class="bigstrip-step-description">Wähle deine Größe</div>

    <div class="bigstrip-size-chooser-container">
      <bigstrip-size-previewer ref="preview" v-on:selectSize="selectSize"/>
      <bigstrip-amount-chooser ref="amount" v-on:resetDiscount="resetDiscount" v-on:chooseAmount="chooseAmount"/>
    </div>
  </div>
</template>

<script>
import BigstripSizePreviewer from "@/components/orderpage/sizechooser/BigstripSizePreviewer";
import BigstripAmountChooser from "@/components/orderpage/sizechooser/BigstripAmountChooser";

export default {
  name: "BigstripSizeChooser",
  components: {
    BigstripSizePreviewer,
    BigstripAmountChooser,
  },
  methods: {
    async resetDiscount() {
      await this.$refs.amount.setDiscount(0)
    },
    async selectSize(size) {
      let product = await this.$store.getters.getProducts[size]
      await this.$refs.preview.setSize(size)

      await this.$store.dispatch('setSize', size)

      let amount = await this.$store.getters.getAmount
      if (amount) {
        await this.$refs.preview.setTotal(await this.calcTotal(size, amount))
        if (amount === 2) {
          await this.$refs.amount.setDiscount(product['discount'])
        } else {
          await this.$refs.amount.setDiscount(0)
        }
      }
      await this.$emit('validate', this.$store.getters.isSizeAndAmountProvided)
      this.$forceUpdate()
    },
    async chooseAmount(amount) {
      await this.$refs.amount.setAmount(amount)
      await this.$store.dispatch('setAmount', amount)

      let size = await this.$store.getters.getSize
      if (size) {
        let product = await this.$store.getters.getProducts[size]
        await this.$refs.preview.setTotal(await this.calcTotal(size, amount))
        if (amount === 2) {
          await this.$refs.amount.setDiscount(product['discount'])
        } else {
          await this.$refs.amount.setDiscount(0)
        }
      }
      await this.$emit('validate', this.$store.getters.isSizeAndAmountProvided)
      this.$forceUpdate()
    },
    async calcTotal(size, amount) {
      if (size && amount) {
        let product = await this.$store.getters.getProducts[size]
        let total = amount * product['price'] - (amount === 2 ? product['discount'] : 0)
        return total
      }
    }
  },
  async mounted() {

    let size = await this.$store.getters.getSize
    if (size !== undefined) await this.selectSize(size)

    let amount = await this.$store.getters.getAmount
    if (amount !== undefined) await this.chooseAmount(parseInt(amount))

    if (await this.$store.getters.isSizeAndAmountProvided) {
      await this.$refs.preview.setTotal(await this.calcTotal(size, amount))
    }

    await this.$emit('setupButton', {
      text: 'WEITER',
      visible: true
    })
    await this.$emit('validate', this.$store.getters.isSizeAndAmountProvided)
  }
}
</script>

<style scoped>
  #BigstripSizeChooserContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
    ". . .";
    align-items: end;
  }

  #BigstripSizeChooserMeta {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
    ". . .";
    align-items: start;
  }

  .bigstrip-size-chooser-container {
    margin: 20px;
  }

</style>
