<template>
    <div>
        <div class="BigstripContactUsLine">
            <img v-bind:src="thumbnail" />
            <span>{{text}}</span>
            <a class="bigstrip-contact-us-line-cta" v-bind:href="link">{{cta}}</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BigstripContactUs",
        props: ['thumbnail', 'text', 'cta', 'link']
    }
</script>

<style scoped>
    .BigstripContactUsLine {
        display: flex;
        align-items: center;
        margin: 15px 0px;
    }

    .BigstripContactUsLine img {

    }

    .BigstripContactUsLine span {
        margin-left: 22px;
        width: 100%;
        text-align: left;
    }

    .BigstripContactUsLine a {
        color: white !important;
        background: var(--bigstrip-color) !important;
        text-decoration: none !important;
        font-size: 0.6rem;
        padding: 6px 32px;
        border-radius: 16px;
        white-space: nowrap;
    }

    .bigstrip-contact-us-line-cta {
      min-width: 60px;
    }

</style>