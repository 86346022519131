<template>
  <div class="radio-button" v-on:click="handleClick" :width="size" :height="size">

    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         v-if="checked"
         :width="size" :height="size"
         viewBox="0 0 283.5 283.5" style="enable-background:new 0 0 283.5 283.5;" xml:space="preserve">
        <circle class="st0" cx="141.7" cy="141.7" r="138.4"/>
        <circle class="st1" cx="141.7" cy="141.7" r="55.3"/>
    </svg>

    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         v-if="!checked"
         :width="size" :height="size"
         viewBox="0 0 283.5 283.5" style="enable-background:new 0 0 283.5 283.5;" xml:space="preserve">
          <circle class="st2" cx="141.8" cy="141.8" r="134"/>
    </svg>

  </div>
</template>

<script>
export default {
  name: "RadioButton",
  data() {
    return {
      size: '30px',
      checked: false
    }
  },
  methods: {
    handleClick() {
      this.check()
      this.$emit('stateChange')
    },
    check() {
      this.checked = true
    },
    uncheck() {
      this.checked = false
    }
  }
}
</script>

<style scoped>

.radio-button {
  display: inline-block;
}

.st0{fill:#cc0099;stroke:#cc0099;stroke-miterlimit:10;}
.st1{fill:#FFFFFF;stroke:#cc0099;stroke-miterlimit:10;}
.st2{fill:none;stroke:#cc0099;stroke-width:9.6823;stroke-miterlimit:10;}

</style>