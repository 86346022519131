<template>
  <div class="bigstrip-locations">
    <div style="height: 300px; width: 100%">
      <vue-modaltor
          :visible="modalVisible"
          @hide="modalVisible = false"
          :close-scroll="true"
          :resize-width='{1920: "30%", 1200:"30%",992:"30%",768:"30%"}'
      >
        <template #header>
          <div></div>
        </template>
        <template #body>
          <bigstrip-location-details ref="location_details">

          </bigstrip-location-details>
        </template>
      </vue-modaltor>
      <l-map ref="map"
             v-if="mapVisible"
             :zoom="zoom"
             :center="center"
             :options="mapOptions"
             style="height: 80%; z-index: 1"
             @update:center="centerUpdate"
             @update:zoom="zoomUpdate"
      >
        <l-tile-layer
            :url="url"
        />
        <l-marker
            v-for="cabine in cabines"
            :key="cabine.id"
            :lat-lng="getLatLng(cabine)"
            @click="showCabineDetails(cabine)"
        >
        </l-marker>
      </l-map>
    </div>
    <ul class="bigstrip-locations-list">
      <li v-for="(cabine, index) in nearestThreeCabines" :key="cabine.id">
        <div class="bigstrip-locations-list-header">{{ cabine.location.name.split(',')[0] }}</div>
        <div>MISSING DATA</div>
        <div>{{ printDistance(cabine.distance) }}</div>
        <hr v-if="index !== 2"/>
      </li>
    </ul>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import { Icon } from 'leaflet';
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import BigstripLocationDetails from "@/components/mainpage/BigstripLocationDetails";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('../../assets/mainpage/pin.svg'),
  iconUrl: require('../../assets/mainpage/pin.svg'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
    name: "BigstripLocations",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    BigstripLocationDetails,
  },
  data() {
    return {
      mapVisible: false,
      zoom: 8,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      currentZoom: 11.5,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      cabines: [],
      nearestThreeCabines: [],
      modalVisible: false
    };
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    async setupMap(long, lat) {
      let context = this
      this.center = latLng(lat, long)
      this.currentCenter = latLng(lat, long)
      this.mapVisible = true

      async function waitTillMapIsRendered() {
        return new Promise(resolve => {
          let watch = setInterval(function () {
            // eslint-disable-next-line no-undef
            if (context.$refs.map && context.$refs.map.mapObject !== undefined) {
              clearInterval(watch)
              resolve()
            }
          }, 500)
        })
      }

      await waitTillMapIsRendered()

      let map = this.$refs.map.mapObject
      //map.panTo(latLng(lat, long))

      const plugin = document.createElement("script");
      plugin.setAttribute(
          "src",
          "js/leaflet-providers.js"
      );
      plugin.async = false;
      document.head.appendChild(plugin);


      async function waitTillProviderScriptInitialized() {
        return new Promise(resolve => {
          let watch = setInterval(function () {
            // eslint-disable-next-line no-undef
            if (L.tileLayer.provider !== undefined) {
              clearInterval(watch)
              resolve()
            }
          }, 500)
        })
      }

      await waitTillProviderScriptInitialized()

      // eslint-disable-next-line no-undef
      L.tileLayer.provider('MapBox', {
        id: 'bernhards/ckpwcfb2h325p17pe31omri0n',
        accessToken: 'pk.eyJ1IjoiYmVybmhhcmRzIiwiYSI6ImNrcHdjY2xweTFyMGcycHFrNmNlaTg1YnMifQ.7p92Xl6z4DfauSrPB9hWvQ'
      }).addTo(map);
    },
    setupMarkers(long, lat) {
      let context = this
      fetch('https://my.blitzkabine.com/api/booths/locations?withThumbnail=true')
          .then(x => x.json())
          .then((data) => {
            data.forEach(cabine => {
              cabine['distance'] = distance(lat, long, cabine['location']['lat'], cabine['location']['long'])
              context.cabines.push(cabine)
            })

            context.cabines.sort(function(c1, c2) {
              return c1['distance'] - c2['distance']
            })

            for (let n = 0; n < 3 && n < context.cabines.length; n++) {
              context.nearestThreeCabines.push(context.cabines[n])
            }

          });
    },
    getLatLng(cabine) {
      return latLng(cabine['location']['lat'], cabine['location']['long'])
    },
    showCabineDetails(cabine) {
      this.modalVisible = true
      this.$refs.location_details.update(cabine)

      let context = this

      document.getElementsByClassName('modaltor__overlay')[0].addEventListener('click', function () {
        context.modalVisible = false
      })
    },
    printDistance(distance) {
      if (distance >= 1) {
        let withoutDecimal = Math.floor(distance)
        let withDecimal = Math.floor(distance*10)
        return withoutDecimal + ","+Math.abs(withoutDecimal*10 - withDecimal)+" km entfernt"
      } else {
        return Math.floor(distance*1000)+' m entfernt'
      }
    }
  },
  mounted() {

    let context = this

    function getPositionViaIp(){
      fetch('https://api.ipify.org?format=json')
          .then(x => x.json())
          .then(({ ip }) => {
            fetch('http://ip-api.com/json/'+ip)
                .then(x => x.json())
                .then((data) => {
                  let long = data['lon']
                  let lat = data['lat']
                  context.setupMap(long, lat)
                  context.setupMarkers(long, lat)
                });
          });
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let long = position['coords']['longitude']
        let lat = position['coords']['latitude']
        context.setupMap(long, lat)
        context.setupMarkers(long, lat)
      }, err => {
        console.error(err)
        getPositionViaIp()
      });
    } else {
      getPositionViaIp()
    }
  }
}

function distance(lat1, lon1, lat2, lon2) {
  var p = 0.017453292519943295;    // Math.PI / 180
  var c = Math.cos;
  var a = 0.5 - c((lat2 - lat1) * p)/2 +
      c(lat1 * p) * c(lat2 * p) *
      (1 - c((lon2 - lon1) * p))/2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

</script>

<style scoped>

  .modaltor {
    z-index: 999999;
  }

  .bigstrip-locations {
    padding: 15px;
  }

  .bigstrip-locations-list-header {
    font: normal normal bold 16px/20px Neusa Next Std
  }

  .bigstrip-locations-list {
    list-style-type: none;
    padding: 0px;
    text-align: left;
  }

  .vue2leaflet-map {
    height: 100% !important;
  }

</style>