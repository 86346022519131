<template>
  <div class="bigstrip-general-form-data">
    <div class="bigstrip-general-form-data-grid-container">
      <bigstrip-form-entry ref="firstname" @update="onUpdate" class="form_firstname">Vorname</bigstrip-form-entry>
      <bigstrip-form-entry ref="lastname" @update="onUpdate" class="form_lastname">Nachname</bigstrip-form-entry>
      <bigstrip-form-entry ref="street" @update="onUpdate" class="form_street">Straße und Hausnummer</bigstrip-form-entry>
      <bigstrip-form-entry ref="postal_code" @update="onUpdate" class="form_postal_code">Postleitzahl</bigstrip-form-entry>
      <bigstrip-form-entry ref="city" @update="onUpdate" class="form_city">Stadt</bigstrip-form-entry>
      <bigstrip-form-entry ref="country" @update="onUpdate" class="form_country">Land</bigstrip-form-entry>
      <bigstrip-form-entry ref="phone_nr" @update="onUpdate" class="form_phone_nr">Telefonnummer</bigstrip-form-entry>
      <bigstrip-form-entry ref="email" @update="onUpdate" class="form_email">E-Mail Adresse</bigstrip-form-entry>
    </div>
  </div>
</template>

<script>

import BigstripFormEntry from "@/components/orderpage/form/BigstripFormEntry";

export default {
  name: "BigstripGeneralFormData",
  components: {
    BigstripFormEntry
  },
  data() {
    return {
      valid: false
    }
  },
  methods: {
    onUpdate() {
      this.valid = this.checkIfAllIsValid()
      if (this.valid) {
        this.$emit('update', {
          firstname: this.$refs.firstname.getValue(),
          lastname: this.$refs.lastname.getValue(),
          street: this.$refs.street.getValue(),
          postal_code: this.$refs.postal_code.getValue(),
          city: this.$refs.city.getValue(),
          country: this.$refs.country.getValue(),
          phone_nr: this.$refs.phone_nr.getValue(),
          email: this.$refs.email.getValue()
        })
      } else {
        this.$emit('update', null)
      }
    },
    checkIfAllIsValid() {
      return this.$refs.firstname.isValid()
              && this.$refs.lastname.isValid()
              && this.$refs.street.isValid()
              && this.$refs.postal_code.isValid()
              && this.$refs.city.isValid()
              && this.$refs.country.isValid()
              && this.$refs.phone_nr.isValid()
              && this.$refs.email.isValid()
    },
    initForm(data) {
      this.$refs.firstname.setValue(data['firstname'])
      this.$refs.lastname.setValue(data['lastname'])
      this.$refs.street.setValue(data['street'])
      this.$refs.postal_code.setValue(data['postal_code'])
      this.$refs.city.setValue(data['city'])
      this.$refs.country.setValue(data['country'])
      this.$refs.phone_nr.setValue(data['phone_nr'])
      this.$refs.email.setValue(data['email'])
    },
    isValid() {
      return this.valid
    }
  },
  mounted() {
    this.$refs.postal_code.setType('number')
    this.$refs.phone_nr.setType('tel')
    this.$refs.email.setType('email')
  }
}
</script>

<style scoped>
.bigstrip-general-form-data-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 12px 12px;
  margin: 15px;
  grid-template-areas:
    "form_firstname form_lastname"
    "form_street form_street"
    "form_postal_code form_city"
    "form_country form_country"
    "form_phone_nr form_phone_nr"
    "form_email form_email";
}
.form_street { grid-area: form_street; }
.form_firstname { grid-area: form_firstname; }
.form_lastname { grid-area: form_lastname; }
.form_postal_code { grid-area: form_postal_code; }
.form_city { grid-area: form_city; }
.form_country { grid-area: form_country; }
.form_phone_nr { grid-area: form_phone_nr; }
.form_email { grid-area: form_email; }
</style>