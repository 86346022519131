<template>
  <div>
    <div class="bigstrip-step-headline">FOTOS LADEN</div>
    <div class="bigstrip-step-description">Lade dir bis zu drei Streifen um die Fotos später Individuell platzieren zu können.</div>
    <div id="BigstripImageImporterContainer">
      <div id="BigstripImageImporterLoading" v-if="this.$store.getters.isLoading"><img src="../../assets/loading.gif"/></div>
    </div>
    <div v-if="this.$store.getters.getImageRows.length > 0">
      <bigstrip-image-row
              v-for="image_row in this.$store.getters.getImageRows"
              :key="image_row.code"
              :images="image_row.images"
              :code="image_row.code"
              v-on:removeImageRow="removeImageRow"
      >
      </bigstrip-image-row>
    </div>
    <div id="BigstripImageImporterInput">
      <input v-model="code" placeholder="XX-XXXX" maxlength="7">
      <button v-on:click="importPhotos()">+</button>
    </div>
    <div v-if="this.$store.getters.getImageRows.length === 0">
      <bigstrip-image-row></bigstrip-image-row>
    </div>
  </div>
</template>

<script>

  import BigstripImageRow from "@/components/orderpage/imageimporter/BigstripImageRow";


export default {
  name: "BigstripImageImporter",
  components: {BigstripImageRow},
  data: function () {
    return {
      code: '',
      numberOfColumns: 3,
    }
  },
  methods: {
    importPhotos: async function () {
      this.$emit('import', this.code)
    },
    async removeImageRow(code) {
      await this.$store.dispatch('removeImageRow', code)
      await this.$emit('validate', this.$store.getters.getImageRows.length > 0)
    }
  },
  watch: {
    code() {
      let realNumber = this.code.replace(/-/gi, '')
      // Generate dashed number
      let dashedNumber = realNumber.match(/.{1,2}/g)

      if (!dashedNumber) {
        this.code = ''
      } else if (dashedNumber.length === 1) {
        this.code = dashedNumber[0]
      } else {
        this.code = dashedNumber[0] + '-'
        dashedNumber = dashedNumber.slice(1)
        this.code += dashedNumber.join('')
      }
      this.code = this.code.toUpperCase()
    }
  },
  async mounted() {
    await this.$store.dispatch('setLoading', false)
    await this.$emit('setupButton', {
      text: 'WEITER',
      visible: true
    })
    await this.$emit('validate', this.$store.getters.getImageRows.length > 0)
  }
}
</script>

<style scoped>

  #BigstripImageImporterInput input {
    padding: 8px 5px;
    border: 1px solid var(--bigstrip-color);
    outline: none;
    color: #aaa;
    font-weight: bold;
    text-align: center;
    font-family: "Courier New";
    font-size: 1.2rem;
  }

  #BigstripImageImporterInput button {
    padding: 8px 5px;
    outline: none;
    color: var(--bigstrip-light-color);
    font-weight: bold;
    text-align: center;
    font-family: "Courier New";
    font-size: 1.2rem;
    background: var(--bigstrip-color);
    border: 1px solid var(--bigstrip-color);
    border-left: none;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    width: 20%;
  }

  #BigstripImageImporterContainer {
    display: grid;
    margin: 0 auto;
  }

  #BigstripImageImporterLoading {
  }

  #BigstripImageImporterLoading img {
    text-align: center;
    opacity: 0.6;
    margin: 50px;
  }

</style>
