<template>
  <div id="BigstripInstaContainer">
    <div class="elfsight-app-15eb834d-e151-4ce2-99cc-5d347c322260"></div>
  </div>
</template>

<script>
export default {
name: "BigstripInstaContainer"
}
</script>

<style scoped>

  #BigstripInstaContainer {
    height: auto;
  }

</style>
